import React from 'react';
import Select from 'react-select';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, onSetBivariateIndicator, onSetActiveIndicator, useGlobalActiveGeography } from '../../data/StatusStore';
import Colors from '../utilities/Colors';

interface IndicatorDropdownProps {
  options: any;
}

const IndicatorDropdown: React.FC<IndicatorDropdownProps> = ({ options }) => {
  console.log("A021125 options: ", options);
  const activeIndicator = useGlobalActiveIndicator();
  const activeGeography = useGlobalActiveGeography();
  const bivariateIndicator = useGlobalBivariateIndicator();
  //console.log("I100524 activeIndicator: ", activeIndicator);
  //console.log("I100524 bivariateIndicator: ", bivariateIndicator);
  //console.log("I100524 activeGeography: ", activeGeography);
  let formattedOptions:any[] = [];
  Object.keys(options.DISPLAY_NAME).forEach(key => {
    if (options[activeGeography][key] == "X"){
      //console.log("C012125 key: ", key);
      //console.log("C012125 options.Comparisons[key]", options.Comparisons[key]);
      //console.log("C012125 options['Use note for Comparisons'][key]", options['Use note for Comparisons'][key]);
      if (options.Comparisons[key] == "X" && key !== activeIndicator && key !== bivariateIndicator){
        formattedOptions.push(
          {
            value: key,
            label: options.DISPLAY_NAME[key],
            category: options["Category 1"][key]
            //label: options['Use note for Comparisons'][key] === "" ? options.DISPLAY_NAME[key] : options['NOTES'][key]
          }
        );
      }
    }
  });
  const desiredOrder = ["Basics", "Health", "Education", "Work + Wealth", "Safety", "Civics", "Physical World"];
  formattedOptions.sort((a, b) => {
    const categoryComparison = desiredOrder.indexOf(a.category) - desiredOrder.indexOf(b.category);
    if (categoryComparison !== 0) {
      return categoryComparison;
    }
    return a.label.localeCompare(b.label);
  });

  console.log("A021125 formattedOptions: ", formattedOptions);
  const categorizedOptions: any[] = [];
  let currentCategory = '';
  

  formattedOptions.forEach(option => {
    if (option.category !== currentCategory) {
      currentCategory = option.category;
      categorizedOptions.push({
        value: "unavailable",
        label: currentCategory.toUpperCase(),
        category: currentCategory
      });
    }
    categorizedOptions.push(option);
  });
  formattedOptions = categorizedOptions;
  console.log("H020425 categorizedOptions: ", categorizedOptions);
  
  console.log("A021125 formattedOptions: ", formattedOptions);
  console.log("A021125 desiredOrder: ", desiredOrder);
  const handleActiveIndicatorChange = (selectedOption: any) => {
    onSetActiveIndicator(selectedOption.value);
  };

  const handleBivariateIndicatorChange = (selectedOption: any) => {
    onSetBivariateIndicator(selectedOption.value);
  };

  return (
    <div>
      <Select
      key={`${activeGeography}_select_1_${activeIndicator}`}
      options={formattedOptions}
      defaultValue={{ value: activeIndicator, label: options.DISPLAY_NAME[activeIndicator], category:"" }}
      onChange={handleActiveIndicatorChange}
      placeholder="Select Active Indicator"
      isOptionDisabled={(option) => option.value === 'unavailable'}
      styles={{
        option: (provided, state) => ({
        ...provided,
        backgroundColor: state.data.value === 'unavailable' ? Colors.getColorByClass(state.data.category) : Colors.getColorByClass(state.data.category, 1), //provided.backgroundColor,
        color: state.data.value === 'unavailable' ? '#FFF' : provided.color,
        fontWeight: state.data.value === 'unavailable' ? 'bold' : provided.fontWeight,
        cursor: state.data.value === 'unavailable' ? 'default' : provided.cursor,
        }),
      }}
      />
      <Select
      key={`${activeGeography}_select_2_${bivariateIndicator}`}
      options={formattedOptions}
      defaultValue={{ value: bivariateIndicator, label: options.DISPLAY_NAME[bivariateIndicator], category:"" }}
      onChange={handleBivariateIndicatorChange}
      placeholder="Select Bivariate Indicator"
      isOptionDisabled={(option) => option.value === 'unavailable'}
      styles={{
        option: (provided, state) => ({
        ...provided,
        backgroundColor: state.data.value === 'unavailable' ? Colors.getColorByClass(state.data.category) : Colors.getColorByClass(state.data.category, 1), //provided.backgroundColor,
        color: state.data.value === 'unavailable' ? '#FFF' : provided.color,
        fontWeight: state.data.value === 'unavailable' ? 'bold' : provided.fontWeight,
        cursor: state.data.value === 'unavailable' ? 'default' : provided.cursor,
        }),
      }}
      />
    </div>
  );
};

export default IndicatorDropdown;
