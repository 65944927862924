import React, { useState, useEffect, FC } from 'react';
import { MapContainer, TileLayer, GeoJSON, Popup, CircleMarker, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import { feature } from 'topojson-client';
import { debounce } from 'lodash';
import 'leaflet/dist/leaflet.css';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import { useData } from '../../../data/DataContext';
import Colors from '../../utilities/Colors';
import * as turf from 'turf';
import { cleanString, d2gRound, parseActivePointStatus } from '../../utilities/Utilities';
import { onHoverHistogram, onClickHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, 
    useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalBivariateOverRide,
    useGlobalDBModuleId, onSetModuleId, useGlobalModuleId,  useGlobalActiveDBModuleId, useGlobalActiveModuleId, 
    useGlobalActivePage, useGlobalActivePointStatus, onSetActivePointStatus, useGlobalSelectedPoint, useGlobalSelectedPointFeature,
    onSetSelectedPoint } from '../../../data/StatusStore';

interface DataItem {
    id: string;
    value: any;
}

interface PointLayersProps {
    activeGeography: string;
    pane?: string; // Add this line so pane can be passed in
    polypane?: string; // Add this line so pane can be passed in
    setModuleIdIncrement?: Function | undefined;
}

export const PointLayers: FC<PointLayersProps> = (
    { activeGeography, pane, polypane, setModuleIdIncrement }
) => {
    const {
        geoJsonDataPUMA, geoJsonDataTract, 
        geoJsonDataNTA, geoJsonDataBoro,
        isLoadingTopology, error, dataPoint, pointDataList, pointModules
    } = useData();
    const activePointStatus = useGlobalActivePointStatus();
    const globalSelectedPointFeature = useGlobalSelectedPointFeature();
    const [parsedActivePointStatus, setParsedActivePointStatus] = useState<any>(null);
    const [polygonName, setPolygonName] = useState<string>("");
    const [latLng, setLatLng] = useState<any>(["0","0"]);
    const [selectedPoly, setSelectedPoly] = useState<any>(null);
    const selectedPoint = useGlobalSelectedPoint();
    console.log("E012525 PointLayers > selectedPoint", selectedPoint);
    /*const hoveredId = useGlobalHoveredId();
    const selectedId = useGlobalSelectedId();
    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    const bivariateOverRide = useGlobalBivariateOverRide();
    const globalDBModuleId = useGlobalDBModuleId();
    const globalModuleId = useGlobalModuleId();
    const globalActiveDBModuleId = useGlobalActiveDBModuleId();
    const globalActiveModuleId = useGlobalActiveModuleId();
    const activePage = useGlobalActivePage();

    const [activeGeoJSON, setActiveGeoJSON] = useState<FeatureCollection | null>(null);
    const [selectedGeoJSON, setSelectedGeoJSON] = useState<FeatureCollection | null>(null);
    const [mapId, setMapId] = useState<number>(0);
    */
    const handleMarkerClick = (point: any, key:string | null) => {
        console.log("G012825 Marker clicked:", point, key);
        console.log("G012825 PointLayers > activePointStatus", activePointStatus);
        console.log("G012825 PointLayers > parsedActivePointStatus", parsedActivePointStatus);
        console.log("G012825 dataPoint", dataPoint);
        console.log("G012825 pointDataList", pointDataList);
        console.log("G012825 pointModules", pointModules);
        
        if (key) {
            const firstKey = Object.keys(pointModules[key])[0];
            const moduleName = pointModules[key][firstKey][0].Sort["Unique Module ID"];
            console.log("GG moduleName", moduleName);
            console.log("GG cleanString(moduleName)", cleanString(moduleName));
            console.log("C012825 key ? dataPoint[key].meta : null", key ? dataPoint[key].meta : null);
            onSetModuleId(cleanString(moduleName));
            if (setModuleIdIncrement){
                const debounceSetModuleIdIncrement = debounce(() => {
                    setModuleIdIncrement((prev: number) => prev + 1);
                }, 300);
                debounceSetModuleIdIncrement();
            }
        }
        //onSetActivePointStatus(`${point.latitude}*${point.longitude}*${cleanString(key)}`);
        let constructedStatus = ""
        let _point:any = {};
        if (point.geometry) {
            setSelectedPoly(point); // its actually a polygon!
            //onSetSelectedPoint(point);
            const centroid = turf.centroid(point);
            const [centroidLng, centroidLat] = centroid.geometry.coordinates;
            //const centroid = L.polygon(point.geometry.coordinates).getBounds().getCenter();
            _point["latitude"] = centroidLat;//centroid.lat;
            _point["longitude"] = centroidLng; //centroid.lng;
            
            let __name = "";
            Object.keys(point.properties).forEach(propertyKey => {
                _point[propertyKey] = point.properties[propertyKey];
                if (propertyKey.endsWith('_name')) {
                    __name = _point[propertyKey];
                }
            });
            setPolygonName(__name);
        }else{
            _point = point;
        }
        setLatLng([_point.latitude, _point.longitude]);
        //console.log("E012525 _point", _point);
        if (parsedActivePointStatus && key) {
            Object.keys(parsedActivePointStatus).forEach(statusKey => {
                const status = parsedActivePointStatus[statusKey];
                //console.log("F121724 statusKey", statusKey);
                //console.log("F121724 key", key);
                //console.log("F121724 cleanString(key)", cleanString(key));
                //console.log("F121724 status", status);
                if (statusKey === cleanString(key)) {
                    // update lat/lng
                    constructedStatus += constructedStatus === "" 
                        ? `${_point.latitude}*${_point.longitude}*${statusKey}`
                        : `~${_point.latitude}*${_point.longitude}*${statusKey}`;
                }else{
                    // clear lat/lng
                    constructedStatus += constructedStatus === "" 
                    ? `${"na"}*${"na"}*${statusKey}`
                    : `~${"na"}*${"na"}*${statusKey}`;
                }
            });
            onSetActivePointStatus(constructedStatus);
        }
        
    };

    useEffect(() => {
        console.log("A012625 -----  globalSelectedPointFeature", globalSelectedPointFeature);
        console.log("A012625 -----  dataPoint", dataPoint);
        if (globalSelectedPointFeature) {
            handleMarkerClick(globalSelectedPointFeature, null);
        }
    }, [globalSelectedPointFeature]);

    useEffect(() => {
        console.log("CC012825 ----- dataPoint", dataPoint);
    }, [dataPoint]);

    useEffect(() => {
        console.log("B121624 activePointStatus", activePointStatus);
        if (activePointStatus) {
            setParsedActivePointStatus(parseActivePointStatus(activePointStatus));
        }
    }, [activePointStatus]);

    useEffect(() => {
        console.log("D011025 PointLayers > parsedActivePointStatus", parsedActivePointStatus);
    }, [parsedActivePointStatus]);

    const getPolygonStyle = (color: string, key: string, d:any) => {
        //console.log("E012525 getPolygonStyle d", d);
        const firstKey = Object.keys(d.properties)[0];
        return {
            fillColor: color,
            color: selectedPoly 
                ? d.properties[firstKey] == selectedPoly.properties[firstKey] ? Colors.black : color
                : color,
            weight: selectedPoly 
                ? d.properties[firstKey] == selectedPoly.properties[firstKey] ? 2 : 1
                : 1,
            opacity: 1,
            fillOpacity: 0.5,
        };
    }
    return (
        <>
        {console.log("F012825 dataPoint", dataPoint)}
        {dataPoint && Object.keys(dataPoint).map(key => {
            const pointData = dataPoint[key];
            if (pointData.active) {
                if (pointData.data.features) {
                    // This is polygon data
                    //console.log("J011025 dataPoint[key]", dataPoint[key])
                    return (
                        <LayerGroup key={key} pane={polypane || "polyPane"}>
                            <GeoJSON
                                key={`polygon_${key}`}
                                data={pointData.data}
                                pane={polypane || "polyPane"}
                                style={(d) => getPolygonStyle(dataPoint[key].item.color, dataPoint[key].item, d)}
                                eventHandlers={{
                                    click: (e) => handleMarkerClick(e.layer.feature, key)
                                }}
                            >
                                {/*<Popup>
                                    <b>{key}</b><br />
                                    {polygonName ? polygonName : ""}
                                </Popup>*/}
                            </GeoJSON>
                        </LayerGroup>
                    );
                }else{
                    // console.log("EE121624 pointData.active", pointData)
                    // Instead of converting to GeoJSON and using <GeoJSON />, 
                    // we directly create <CircleMarker /> components for each point.
                    return (
                        <LayerGroup key={key} pane={pane || "pointsPane"}>
                            {pointData.data.map((point: any, idx: number) => {
                                let _name = null;
                                Object.keys(point).forEach(pointKey => {
                                    if (pointKey.endsWith('_name')) {
                                        _name = point[pointKey];
                                    }
                                });
                                if (isNaN(Number(point.longitude)) || isNaN(Number(point.latitude))) {
                                    return null;
                                }
                                let selected = false;
                                if (latLng[0] == point.latitude && latLng[1] == point.longitude) {
                                    //console.log("F012525 point.latitude", point.latitude);
                                    //console.log("F012525 latLng[0]     ", latLng[0]);
                                    selected = true;
                                }
                                return (
                                    <CircleMarker
                                        key={`point_${key}_${idx}_${selected ? "selected" : "unselected"}`}
                                        pane={pane || "pointsPane"}
                                        center={[Number(point.longitude), Number(point.latitude)]}
                                        radius={5}
                                        fillColor={dataPoint[key].item.color}
                                        color={"#000"}
                                        //stroke={latLng[0] == point.latitude && latLng[1] == point.longitude ? true : false}
                                        stroke={selected}
                                        weight={2}
                                        opacity={1}
                                        fillOpacity={0.7}
                                        eventHandlers={{
                                            click: () => handleMarkerClick(point, key)
                                        }}
                                    >
                                        {/*<Popup 
                                            //autoPan={false}
                                        >
                                            <b>{key}</b><br />
                                            {_name ? _name : ""}
                                        </Popup>*/}
                                    </CircleMarker>
                                );
                            })}
                        </LayerGroup>
                    );
                }
            }
            return null;
        })}
        </>
    );

};
