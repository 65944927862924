import React, { useState, useEffect, useRef, useTransition } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import { useData } from '../../data/DataContext';

export interface DeviationFromAverageChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 85;
const MARGIN_Y = 10;

const DeviationFromAverage: React.FC<DeviationFromAverageChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [barHeight, setBarHeight] = useState<number>(40);
  const [spaceHeight, setSpaceHeight] = useState<number>(10);
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });
  const [maxDeviation, setMaxDeviation] = useState<number>(1);
  const [stdDeviations, setStdDeviations] = useState<{ [key: string]: number }>({});
  const [resetBars, setResetBars] = useState<{ [key: string]: boolean }>({});
  const [isPending, startTransition] = useTransition();
  const { dataNYC } = useData();
  const [tooltip, setTooltip] = useState<{ x: number; y: number; obj: any } | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<SVGRectElement>, obj: any) => {
    if (containerRef.current) {
      const { nativeEvent } = event; // Access native event
      const offsetX = nativeEvent.offsetX;
      const offsetY = nativeEvent.offsetY;
  
      setTooltip({
        x: offsetX, // Tooltip position now respects container context
        y: offsetY,
        obj,
      });
    }
  };
  
  const handleMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    if (containerRef.current) {
      const { nativeEvent } = event;
      const offsetX = nativeEvent.offsetX;
      const offsetY = nativeEvent.offsetY;
  
      setTooltip((prev) => prev && {
        ...prev,
        x: offsetX,
        y: offsetY,
      });
    }
  };  

  const handleMouseLeave = () => {
    setTooltip(null);
  };

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) return;
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });
      resizeObserver.observe(containerRef.current);
      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    startTransition(() => {
      if (selectedId) prepData();
    });
  }, [selectedId]);

  const calculateStandardDeviation = (values: number[]) => {
    const mean = values.reduce((a, b) => a + b, 0) / values.length;
    return Math.sqrt(values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length);
  };

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => !v.sort.Tooltip_only);
      const _tooltipData = options.variables.filter((v: any) => v.sort.Tooltip_only);
      const _preppedData = _filteredData.map((v: any) => {
        const rawValue = data.dataJSON[selectedId][v.variable];
        const isNA = rawValue === undefined || rawValue === null || rawValue === "NA";
        const value = !isNA && !isNaN(rawValue) ? rawValue : 'N/A';
        const deviation = !isNA && !isNaN(rawValue) ? (dataNYC ? dataNYC.dataJSON[0][v.variable] : null ) - rawValue : 0;
        let ttVariable = '';
        _tooltipData.forEach((s: any) => {
          if (s.sort.Tooltip_only === v.variable) {
            ttVariable = s.variable;
          }
        });
        const tooltip = data.dataJSON[selectedId][ttVariable];
        //console.log("E012125 dataNYC.dataJSON", dataNYC ? dataNYC.dataJSON : null);
        //console.log("E012125 dataNYC.dataJSON[0][v.variable]", dataNYC ? dataNYC.dataJSON[0][v.variable] : null);
        const presentableNYCAvg = dataNYC 
          ? d2gRound(dataNYC.dataJSON[0][v.variable], { DESCRIPTOR: data.meta["DESCRIPTOR"][v.variable] }) 
          : null; 

        const presentableTooltip = tooltip 
          ? d2gRound(tooltip, { DESCRIPTOR: data.meta["DESCRIPTOR"][ttVariable] }) 
          : d2gRound(value, { DESCRIPTOR: data.meta["DESCRIPTOR"][v.variable] }) 

        const presentableLabel = tooltip 
          ? data.meta["DISPLAY_NAME"][ttVariable].split("(")[0]
          : data.meta["DISPLAY_NAME"][v.variable].split("(")[0] 
        console.log("E012125 presentableNYCAvg,", presentableNYCAvg);
        console.log("E012125 data.meta.avg[v.variable],", data.meta.avg[v.variable]);
        return {
          name: v.label,
          deviation: isNA ? 0 : deviation,
          value,
          tooltip: presentableTooltip,
          tooltipLabel: presentableLabel,
          nycAvg: presentableNYCAvg,
          average: dataNYC ? dataNYC.dataJSON[0][v.variable] : null,//data.meta.avg[v.variable],
          max: data.meta.max_value[v.variable],
          min: data.meta.min_value[v.variable],
          variable: v.variable,
          index: data.keyIndexDataArray[v.variable] ? data.keyIndexDataArray[v.variable][selectedId].index : 0,
          na: data.meta.na_count[v.variable],
          len: data.dataArray[v.variable] ? data.dataArray[v.variable].length : 0,
        };
      });

      setPreppedData(_preppedData);

      const _stdDeviations: { [key: string]: number } = {};
      let globalMaxDeviation = 0;

      _filteredData.forEach((v: any) => {
        const avg = dataNYC ? dataNYC.dataJSON[0][v.variable] : null; //data.meta.avg[v.variable];
        const values = Object.values(data.dataJSON)
          .map((d: any) => d[v.variable])
          .filter((value: any) => value !== "NA" && value !== undefined && value !== null);

        if (values.length > 0) {
          _stdDeviations[v.variable] = calculateStandardDeviation(values);
        } else {
          _stdDeviations[v.variable] = 0;
        }

        const deviations = values.map(value => Math.abs(avg - value));
        globalMaxDeviation = Math.max(globalMaxDeviation, ...deviations);
      });

      setMaxDeviation(globalMaxDeviation || 1);
      setStdDeviations(_stdDeviations);

      const newResetBars: { [key: string]: boolean } = {};
      _preppedData.forEach((d: any) => {
        const prev = preppedData.find((p: any) => p.variable === d.variable);
        if (prev && (prev.value >= prev.average) !== (d.value >= d.average)) {
          newResetBars[d.variable] = true;
        }
      });
      setResetBars(newResetBars);
    }
  };

  const barScale = (value: number) => {
    const maxWidth = dimensions.width - (2 * MARGIN_X);
    return ((value / maxDeviation) * maxWidth) / 2.5;
  };

  const onClicked = (variable: string) => {
    if (activePage === "explorer") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    }else{
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  const fontSize = 12;
  const fontSize2 = 12;
  const labelWidth = "305px";
  const chartWidth = `calc(100% - 305px)`;
  //console.log("120824 dataNYC", dataNYC)
  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}>
      <div style={{ marginLeft: '0px', marginRight: '0px', fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, width: labelWidth }}>
        {preppedData.map((d: any, i: number) => (
          <div onClick={() => onClicked(d.variable)} key={`info-${i}`} style={{cursor:'pointer', fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, display: "inline-flex", justifyContent: "space-between", height: `${barHeight}px`, width: "100%"}}>
            <div style={{ fontWeight: 'bold', width: "75px", alignContent: "flex-start" }}>
              {d.value === 'N/A' ? 'N/A' : d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}
            </div>
            <div style={{ fontWeight: '500', width: "calc(100% - 75px)", alignContent: "flex-start", paddingRight: "15px" }}>
              {d.name.split("(")[0]}
            </div>
          </div>
        ))}
      </div>
      <div style={{ fontSize, lineHeight: `${fontSize + 2}px`, display: 'flex', width: chartWidth, position: "relative" }}>
        <svg width={"100%"} height={barHeight * preppedData.length + 20} style={{ display: "inline-block" }}>
          <g transform={`translate(${MARGIN_X * 2}, 0)`}>
            {preppedData.map((d: any, i: number) => {
              const resetting = resetBars[d.variable];
              const barWidth = d.value === 'N/A' ? 0 : barScale(Math.abs(d.deviation));
              const xPosition = d.value >= d.average ? 0 : -barWidth;
              const barColor = Colors.getColorQuintile(d.index, d.na, d.len, colorClass);

              return (
                <g key={`deviation-${i}`} transform={`translate(0, ${i * barHeight})`}>
                  <rect
                    x={resetting ? 0 : xPosition}
                    y={0}
                    width={resetting ? 0 : barWidth}
                    height={barHeight - spaceHeight}
                    fill={barColor}
                    onClick={() => onClicked(d.variable)}
                    style={{ cursor: 'pointer', transition: 'width 0.5s ease, x 0.5s ease, fill 0.5s ease' }}
                    onMouseEnter={(e) => handleMouseEnter(e, d)}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onTransitionEnd={() => {
                      if (resetting) {
                        setTimeout(() => {
                          setResetBars((prev) => ({
                            ...prev,
                            [d.variable]: false,
                          }));
                        }, 0);
                      }
                    }}
                  />
                  {/* TURN OFF STANDARD DEVIATION LINES
                  <line
                    x1={Math.min(barScale(stdDeviations[d.variable]), (dimensions.width - 2 * MARGIN_X) / 2)}
                    y1={0}
                    x2={Math.min(barScale(stdDeviations[d.variable]), (dimensions.width - 2 * MARGIN_X) / 2)}
                    y2={barHeight - spaceHeight}
                    stroke='#9E9C9C'
                    strokeWidth={0.5}
                    strokeDasharray="4"
                  />
                  <text
                    x={Math.min(barScale(stdDeviations[d.variable]), (dimensions.width - 2 * MARGIN_X) / 2) + 5}
                    y={(barHeight - spaceHeight) / 2 + 4}
                    fill='#9E9C9C'
                    fontSize="10px"
                    pointerEvents="none"
                  >
                    1 SD
                  </text>
                  <line
                    x1={Math.max(-barScale(stdDeviations[d.variable]), -(dimensions.width - 2 * MARGIN_X) / 2)}
                    y1={0}
                    x2={Math.max(-barScale(stdDeviations[d.variable]), -(dimensions.width - 2 * MARGIN_X) / 2)}
                    y2={barHeight - spaceHeight}
                    stroke='#9E9C9C'
                    strokeWidth={0.5}
                    strokeDasharray="4"
                  />
                  <text
                    x={Math.max(-barScale(stdDeviations[d.variable]), -(dimensions.width - 2 * MARGIN_X) / 2) + 5}
                    y={(barHeight - spaceHeight) / 2 + 4}
                    fill='#9E9C9C'
                    fontSize="10px"
                    pointerEvents="none"
                  >
                    -1 SD
                  </text>*/}
                </g>
              );
            })}
            <line x1={0} y1={0} x2={0} y2={barHeight * preppedData.length} stroke="black" />
            <text x={0} y={barHeight * preppedData.length + 10} fill="black" fontSize="12px" textAnchor="middle">
              NYC Value
            </text>
          </g>
        </svg>
        {tooltip && (
          <div
            style={{
              position: 'absolute',
              top: tooltip.y - 60,
              left: tooltip.x - 75,
              backgroundColor: '#fff', // Tooltip background
              border: '2px solid #ccc', // Thicker border for visibility
              borderRadius: '6px', // Rounded corners
              padding: '8px 12px',
              pointerEvents: 'none',
              zIndex: 10,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', // Uniform shadow
              fontSize: '12px',
              color: '#333',
              width:'150px',
              height:'50px',
              textAlign: 'left',
              wordBreak: 'break-word', // Ensures long words or strings wrap correctly              
              whiteSpace: 'normal', // Allows text to wrap
              maxWidth: '150px', // Set a max width for the tooltip
              lineHeight: '0.9',
            }}
          >
            <b>{tooltip.obj.tooltip}</b> <span style={{
              fontSize: '10px',
            }}>{tooltip.obj.tooltipLabel}</span><br/><span style={{
              fontSize: '10px',
            }}><b>NYC Value:</b> {tooltip.obj.nycAvg}</span>
            <div
              style={{
                position: 'absolute',
                bottom: '-7px', // Lower the notch below tooltip
                left: '45%', // Center horizontally under the tooltip content
                width: '12px', // Adjust width for the callout notch
                height: '12px',
                backgroundColor: '#fff', // Same as tooltip background
                borderLeft: '2px solid #ccc',
                borderBottom: '2px solid #ccc',
                transform: 'rotate(-45deg)', // Rotate for triangle effect
                boxShadow: '-1px 1px 2px rgba(0, 0, 0, 0.1)', // Same shadow
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviationFromAverage;
