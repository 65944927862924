import React, { useState, useEffect, useRef } from 'react';
import Colors from '../utilities/Colors';
import { useGlobalHoveredId, useGlobalSelectedId } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import { point } from 'turf';

interface ListChartMetaProps {
  pointData: any;
  meta: any;
  setPointData: Function;
  setPointFeatureData: Function;
}

const CountMeta: React.FC<ListChartMetaProps> = ({
  pointData,
  meta,
  setPointData,
  setPointFeatureData,
}) => {

  //const hoveredId = useGlobalHoveredId();
  //const selectedId = useGlobalSelectedId() as any;
  //console.log("H121724 meta", meta);
  //console.log("H121724 pointData", pointData);
  //console.log("A100724 indicator", indicator);
  //console.log("A100724 dataArray", dataArray);
  if (!meta) {
    return null;
  }
  if (!pointData) {
    return null;
  }
  if (!pointData.pointData) {
    return null;
  }
  const closeMe = () => {
    console.log("Close button clicked");
    setPointData(null);
    setPointFeatureData(null);
    // Add your logic to handle the close action here
  };
  // Updated styling as requested:
  // - border-radius: 13px;
  // - border: 1px solid #CCC;
  // - background: #FAF5F5;
  // Font size is now 16px for the entire card.
  // Spacing out the data items more similar to the example provided.
  let _name = pointData.pointData["fac_name"] 
    ? pointData.pointData["fac_name"] 
    : pointData.pointData["school_name"] 
      ? pointData.pointData["school_name"] 
      : pointData.pointData["park_name"] 
        ? pointData.pointData["park_name"] 
        : pointData.pointTitle; 
  return (
    <div 
      key={`parent-count-${pointData.pointTitle}`}
      style={{
        border: '1px solid #CCC',
        borderRadius: '13px',
        background: '#FAF5F5',
        padding: '10px',
        width: '100%',
        fontFamily: 'sans-serif',
        fontSize: '12px',
        lineHeight: '1.4'
      }}
    >
      {/* Top row with bullet and pointTitle */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: meta[pointData.pointTitle].META["OLD D2G COLOR"],
          marginRight: '8px'
        }}></div>
        <strong style={{ fontSize: '14px' }}>{_name}</strong>
        <button 
          onClick={() => closeMe()}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            color: '#ccc',
            marginLeft: 'auto'
          }}
        >
          X
        </button>
      </div>
      <hr style={{ border: 'none', borderTop: '1px solid #aaa', margin: '10px 0 15px 0' }} />

      {Object.keys(meta[pointData.pointTitle]).map((key) => {
        // Preserving console logs
        console.log("A013025 pointData", pointData);
        console.log("A013025 key", key);
        console.log("A013025 meta[pointData.pointTitle]", meta[pointData.pointTitle]);
        console.log("A013025 meta[pointData.pointTitle].META[OLD D2G COLOR]", meta[pointData.pointTitle].META["OLD D2G COLOR"]);
        console.log("A013025 meta[pointData.pointTitle][key]", meta[pointData.pointTitle][key]);
        let _disp = meta[pointData.pointTitle][key]["SUBINDICATOR_DISPLAY_NAME"];
        let disp = meta[pointData.pointTitle][key]["SUBINDICATOR_DISPLAY_NAME"];
        // Increased spacing and unified font size to 16px.
        // "#% " -- 
        // "$# " --
        // "#"   --
        // "# "  --
        // ": #" --
        // "% "  --
        let _val = pointData.pointData[meta[pointData.pointTitle][key]["SUBINDICATOR_VARIABLE_NAME"]];
        let val = "";
        let valtype = "number";
        if (!isNaN(parseFloat(_val))) {
          console.log("A013025 _val", _val);
          console.log("A013025 disp", disp);
          switch (true) {
            case disp.includes("#% "):
              disp = disp.replace("#% ", "");
              val = `${d2gRound(_val, { DESCRIPTOR: "X.#%" })}`;
              break;
            case disp.includes("$# "):
              disp = disp.replace("$# ", "");
              val = `${d2gRound(_val, { DESCRIPTOR: "$X" })}`;
              break;
            case disp.includes("# "):
              disp = disp.replace("# ", "");
              val = `${d2gRound(_val, { DESCRIPTOR: "#" })}`;
              break;
            case disp.includes(": #"):
              disp = disp.replace(": #", "");
              val = `${d2gRound(_val, { DESCRIPTOR: "#" })}`;
              break;
            case disp.includes("% "):
              disp = disp.replace("% ", "");
              val = `${d2gRound(_val, { DESCRIPTOR: "X.#%" })}`;
              break;
            default:
              disp = pointData.pointData[meta[pointData.pointTitle][key]["SUBINDICATOR_VARIABLE_NAME"]];
          }
          //console.log("H011025 val --->", val);
          //console.log("H011025 disp --->", disp);
        }else{
          val = _val ? _val : "N/A";
          valtype = "string";
        }
        if (disp === val) {
          val = "Yes";
        }
        let off = false;
        if ((disp === "") && ( val.trim() === "")) {
          off = true
        }
        console.log("A021225 val", val);
        console.log("A021225 disp", disp);
        console.log("A021225 valtype", valtype);
        switch (true) {
          case disp.includes(": #"):
            disp = disp.replace(": #", "");
            valtype = "acceptable string"
            break;
          case val.includes("is"):
            disp = disp.replace("#", "");
            valtype = "acceptable string"
            break;
          default:
            disp = disp;
        }
        return (
          meta[pointData.pointTitle][key]["SUBINDICATOR_VARIABLE_NAME"] === 'fac_name' || meta[pointData.pointTitle][key]["SUBINDICATOR_VARIABLE_NAME"] === 'school_name' || meta[pointData.pointTitle][key]["SUBINDICATOR_VARIABLE_NAME"] === 'park_name' || _disp === "" || off
          ? null
          : <div key={`${key}-count-${pointData.pointTitle}`} style={{ display: 'flex', marginBottom: '12px' }}>
              <div style={{
                width: (valtype === "number" || valtype === "acceptable string") ? '20%' : '100%',
                fontWeight: 'bold',
                textAlign: 'left',
                marginRight: '10px'
              }}>
                {val}
              </div>
              <div style={{ color: '#555', flex: '1', width: (valtype === "number" || valtype === "acceptable string") ? '80%' : '0%', }}>
                {(valtype === "number" || valtype === "acceptable string")
                  ? disp 
                  : ""}
              </div>
            </div>
        );
      })}
    </div>
  );
};

export default CountMeta;
