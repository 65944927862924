import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import Colors from '../utilities/Colors';
import { getGeoName } from '../utilities/Utilities';
// Import the functions directly from the store
import { Histogram } from '../charts/Histogram';
import { useData } from '../../data/DataContext';
import { onHoverHistogram, useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalDBModuleId, useGlobalModuleId, useGlobalActiveDBModuleId, useGlobalActiveModuleId, useGlobalActivePage, onSetActivePage } from '../../data/StatusStore';
import * as turf from 'turf';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import ExportModal from '../inputs/ExportModal';

interface DataItem {
    id: string;
    value: any; // Consider specifying a more detailed type here
}

export interface MainHistogramProps {
  dataGeo: any;
  histMax: any;
  histNA: any;
  histLength: any; 
  chartId: string;
  groupedModules?: any;
  unGroupedModules?: any;
}

export const MainHistogram: React.FC<MainHistogramProps> = ({ 
    dataGeo, histMax, histNA, histLength, chartId, groupedModules, unGroupedModules
}) => {
    /*console.log("A020425 histMax", histMax);
    console.log("A020425 histNA", histNA);
    console.log("A020425 histLength", histLength);
    console.log("A020425 dataGeo", dataGeo);
    
    console.log("A020425 chartId", chartId);*/
    const activeIndicator = useGlobalActiveIndicator();
    const activeGeography = useGlobalActiveGeography();
    const globalDBModuleId = useGlobalDBModuleId();
    const globalModuleId = useGlobalModuleId();
    const globalActiveDBModuleId = useGlobalActiveDBModuleId();
    const globalActiveModuleId = useGlobalActiveModuleId();
    const activePage = useGlobalActivePage();
    const {
        geoJsonDataPUMA, geoJsonDataTract, 
        geoJsonDataNTA, geoJsonDataBoro,
        isLoadingTopology, error, dataPoint, setDataPoint, pointDataList, pointModules
    } = useData();
    /*console.log("A020425 activeIndicator", activeIndicator);
    console.log("A020425 activeGeography", activeGeography);
    console.log("A020425 globalModuleId", globalModuleId);
    console.log("A020425 globalActiveModuleId", globalActiveModuleId);
    console.log("A020425 dataPoint", dataPoint);
    console.log("A020425 pointDataList", pointDataList);
    console.log("A020425 pointModules", pointModules);
    console.log("A020425 dataGeo.meta.DISPLAY_NAME[activeIndicator]", dataGeo.meta.DISPLAY_NAME[activeIndicator]);*/

    const [isModalVisible, setModalVisible] = useState(false);
    const [intersectedPts, setIntersectedPts] = useState<any>([]);

    const openModal = () => setModalVisible(true);
    const closeModal = () => setModalVisible(false);

    const height = '135';
    const width = '100%'; //calc(100% - 40px)';
    const paddingLeft = '0px'; 
    const paddingRight = '0px'; 

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        //console.log("AA020425  dataPoint", dataPoint);
        if (dataPoint && !dataGeo?.dataArray[activeIndicator]) {
            if (!dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]].intersected || !dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]].intersected[activeGeography]) {
            //console.log("AA020425  dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]]", dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]]);
            //console.log("AA020425  activeGeography", activeGeography);
            let tempGeoJSON = geoJsonDataTract;
            if (activeGeography === "PUMA" || activeGeography === "CD") {
                tempGeoJSON = geoJsonDataPUMA;
            } else if (activeGeography === "Tract") {
                tempGeoJSON = geoJsonDataTract;
            } else if (activeGeography === "NTA") {
                tempGeoJSON = geoJsonDataNTA;
            } else if (activeGeography === "Boro") {
                tempGeoJSON = geoJsonDataBoro;
            }
            //console.log("AA020425 tempGeoJSON", tempGeoJSON);
            let points = dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]];
            let polys = tempGeoJSON;
            //console.log("AA020425 points", points);
            //console.log("AA020425 polys", polys);
            if (points && polys) {
                let intersected = getPointsInPolys(points.data, polys);
                //console.log("AA020425 intersected", intersected);
                setIntersectedPts(intersected);
                let _dataPoint = JSON.parse(JSON.stringify(dataPoint));
                if (_dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]]["intersected"]){
                    _dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]]["intersected"][activeGeography] = intersected;
                }else{
                    _dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]]["intersected"] = {};
                    _dataPoint[dataGeo.meta.DISPLAY_NAME[activeIndicator]]["intersected"][activeGeography] = intersected;
                }
                //console.log("AA020425 _dataPoint", _dataPoint)
                setDataPoint(_dataPoint);
            }
            //console.log("AA020425 dataGeo?.dataArray[activeIndicator]", dataGeo?.dataArray[activeIndicator]);
            //console.log("AA020425 unGroupedModules[activePage === explorer ? globalActiveModuleId : globalActiveDBModuleId][0].Sort.Color", unGroupedModules[activePage === "explorer" ? globalActiveModuleId : globalActiveDBModuleId][0].Sort.Color);
            }
        }
    }, [dataPoint, activeGeography, activeIndicator]);

    
    const getPointsInPolys = (points: any[], polys: any) => {
        const result: Record<string, number> = {}; // Object to store counts by GEOID20
    
        points.forEach((point: any) => {
            const pt = turf.point([point.latitude, point.longitude]); // Ensure correct coordinate order
    
            polys.features.forEach((feature: any) => {
                const geoid = feature.properties?.GEOID20; // Ensure GEOID20 exists
                if (!geoid || !pt || !feature?.geometry) return; // Skip invalid data
    
                if (booleanPointInPolygon(pt, feature)) {
                    result[geoid] = (result[geoid] || 0) + 1; // Increment count
                } else {
                    result[geoid] = result[geoid] || 0; // Ensure key exists even if count is 0
                }
            });
        });
    
        // Convert object to sorted array
        const sortedArray = Object.entries(result)
            .map(([id, value]) => ({ id, value })) // Convert to array format
            .sort((a, b) => a.value - b.value); // Sort by value (ascending)
    
        // Determine maxValue
        const maxValue = sortedArray.length > 0 ? sortedArray[sortedArray.length - 1].value : 0;
    
        // Create indexed object with ranking
        const indexedObject: Record<string, { value: number; index: number }> = {};
        sortedArray.forEach(({ id, value }, index) => {
            indexedObject[id] = { value, index };
        });
    
        return { sortedArray, indexedObject, maxValue };
    };
    
    

    //console.log("A112724 globalDBModuleId", globalDBModuleId);
    //console.log("A112724 globalModuleId", globalModuleId);
    //console.log("A112724 dataGeo", dataGeo);
    //console.log("A112724 groupedModules", groupedModules);
    //console.log("A112724 unGroupedModules", unGroupedModules);
    //console.log("A112724 unGroupedModules[activePage === explorer ? globalModuleId : globalDBModuleId]", unGroupedModules[activePage === "explorer" ? globalModuleId : globalDBModuleId]);
    //console.log("AAA112724 unGroupedModules[activePage === explorer ? globalModuleId : globalDBModuleId][0].Sort.Color", unGroupedModules[activePage === "explorer" ? globalModuleId : globalDBModuleId][0].Sort.Color);
    //console.log("A112724 globalDBModuleId", globalDBModuleId);
    //console.log("083024 activeIndicator", activeIndicator);
    //console.log("083024 dataGeo?.meta?.DISPLAY_NAME", dataGeo?.meta?.DISPLAY_NAME);
    //console.log("083024 dataGeo?.meta.DISPLAY_NAME[activeIndicator]", dataGeo?.meta.DISPLAY_NAME[activeIndicator]);

    const changePage = (newPage: string) => {
    const newSearchParams = new URLSearchParams(location.search);
    //newSearchParams.set('page', newPage); // Update the 'page' parameter
    onSetActivePage(newPage);
    // Construct the URL with parameters in the correct order
    const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography', 'activeIndicator', 'bivariateIndicator'];
    const orderedParams = new URLSearchParams();
    
    paramsOrder.forEach(param => {
        const value = newSearchParams.get(param);
        if (value) orderedParams.set(param, value);
    });

    navigate(`/?${orderedParams.toString()}`, { replace: true });
    
    };

    if (!unGroupedModules) {
        return null;
    }
    return (
        <>  
            <div 
            id='mainHistogramContext'
            style={{ 
                padding:"25px",
                paddingBottom:"0px",
                display: "inline-flex",
                height:'calc(100% - 135px)',
            }}>
                <div style={{  
                        
                    }}>
                    <div style={{ 
                        //fontWeight:"bold",
                        //height:'100%',
                        paddingRight:'5px',
                        maxHeight:'75px',
                        width: '100%', 
                        overflowY: "hidden", 
                        overflowX: "hidden", 
                        display: "inline-flex",
                    }}>
                        <div
                            style={{
                            fontSize: "24px",
                            lineHeight: "24px",
                            color: "#231f20",
                            overflowY: "hidden",
                            height: "75px",
                            }}
                        >{dataGeo?.meta.DISPLAY_NAME[activeIndicator] ? dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[0] : ""}</div>
                        {/*<div
                            style={{
                            fontSize: "12px",
                            lineHeight: "28px",
                            paddingLeft:"5px",
                            color: "#5a5a5a",
                            }}
                        >{` (${dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[1]}`}</div>  */}  
                    </div> 
                    <div id="geoRaceContext" style={{ fontSize:"12px", width: '100%', paddingTop:"5px", paddingBottom:"10px", lineHeight:"14px"}}> by <b>{getGeoName(activeGeography)}</b></div>
                    <div 
                        id="descriptionContext"
                        style={{ 
                            fontSize:"12px", 
                            width: '100%', 
                            marginBottom:"10px", 
                            lineHeight:"14px",
                            overflow: "hidden",
                            height: "calc(100% - 135px)",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "6", /* Number of lines to show before truncating */
                            WebkitBoxOrient: "vertical",
                        }}>
                            {dataGeo?.meta.NOTES[activeIndicator]}
                        </div>
                    <div id="sourceContext" style={{ fontSize:"12px", width: '100%', lineHeight:"14px"}}>
                        <span style={{ 
                            color:"#9E9C9C",
                            //fontWeight:"bold",
                        }}
                        >Source: <a className="source-link" href={`${dataGeo?.meta["SOURCE URL"][activeIndicator]}`} target='_blank'>{`${dataGeo?.meta["SOURCE NAME"][activeIndicator]}`}</a></span>
                        <span style={{
                            paddingLeft:"15px",
                        }}></span>
                        <span
                            style={{
                                fontSize:"12px",
                                width: '100%', 
                                lineHeight:"14px",
                                color:"#9E9C9C",
                            }}
                        ><br/>Year: {dataGeo?.meta["YEAR"][activeIndicator]}</span>
                        {/*<span
                            onClick={() => {
                                alert(`this will bring up a modal displaying the indicator(${activeIndicator}'s) metadata`)
                            }}
                            style={{
                                fontStyle:"italic",
                                textDecoration:"underline",
                                textUnderlineOffset:"1px",
                                //fontWeight:"bold",
                                cursor: "pointer",
                            }}
                        ><br/>more info...</span>*/}
                        </div>
                </div>
                <div id='legendExport'>
                    <div style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingRight:"2.5px",
                            paddingLeft:"2.5px",
                            lineHeight:"16px",
                            height:"20px",
                            cursor: "pointer",
                        }}
                        className='compare-button'
                        onClick={() => {
                            changePage('compare');
                            //alert(`this will bring you to the /compare page with the current indicator (${activeIndicator}) and geography (${activeGeography}) selected`)
                        }}
                    >compare</div>
                    <div style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingRight:"2.5px",
                            paddingLeft:"2.5px",
                            marginTop:"5px",
                            lineHeight:"16px",
                            height:"20px",
                            cursor: "pointer",
                        }}
                        className='compare-button'
                        onClick={() => {
                            //alert(`this will begin an export`)
                            openModal();
                        }}
                    >export/share</div>
                    <ExportModal 
                        title={dataGeo?.meta.DISPLAY_NAME[activeIndicator] ? dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[0] : ""} 
                        displayName={dataGeo?.meta.DISPLAY_NAME[activeIndicator]} 
                        year={dataGeo?.meta.YEAR[activeIndicator]} 
                        isVisible={isModalVisible} 
                        onClose={closeModal} 
                        dataGeo={dataGeo}
                        activeIndicator={activeIndicator}
                        bivariateIndicator={null}
                    />
                    </div>
            </div>
            <div style={{ 
                //paddingLeft:"25px",
                //paddingRight:"25px",
                //position:"relative",
                width: "calc(100% - 50px)",
                position:"relative",
                left:"25px",
            }}>
                <Histogram 
                    key={`main_hist_${activeIndicator}`}
                    dataArray={dataGeo?.dataArray[activeIndicator] ? dataGeo?.dataArray[activeIndicator] : intersectedPts?.sortedArray?.length > 0 ? intersectedPts.sortedArray : []}
                    histMax={histMax[activeIndicator] ? histMax[activeIndicator] : intersectedPts.maxValue ? intersectedPts.maxValue : 0}
                    histNA={histNA[activeIndicator] ? histNA[activeIndicator] : 0} 
                    histLength={histLength[activeIndicator] ? histLength[activeIndicator] : intersectedPts?.sortedArray?.length > 0 ? intersectedPts?.sortedArray?.length : 0 } 
                    chartId={activeIndicator}
                    height={height}
                    width={width}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    tooltips={true}
                    //colorClass={dataGeo?.meta["Module Color"][activeIndicator]}
                    colorClass={unGroupedModules[activePage === "explorer" ? globalActiveModuleId : globalActiveDBModuleId][0].Sort.Color}
                    data={dataGeo}
                    indicator={activeIndicator}
                />
            </div>
        </>
    );
};
