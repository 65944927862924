import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useGlobalSelectedId, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';

export interface PyramidChartProps {
  options: any;
  data: any;
  width: number; // This parameter will be ignored
  height: number;
  colorClass: string;
}

const Pyramid: React.FC<PyramidChartProps> = ({ options, data, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [ageGroups, setAgeGroups] = useState<string[]>([]);
  const [barHeight, setBarHeight] = useState<number>(20);
  const [maxFemaleValue, setMaxFemaleValue] = useState<number>(0);
  const [maxMaleValue, setMaxMaleValue] = useState<number>(0);
  const [tooltip, setTooltip] = useState<{ x: number; y: number; obj: any } | null>(null); // Tooltip state
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);

  //console.log("E112624 selectedId", selectedId)
  //console.log("E112624 options, data, height, colorClass ", options, data, height, colorClass )

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const combinedData: any[] = [];
      //const _filteredData = options.variables.filter((v: any) => v.sort.Tooltip_only === "");
      const _filteredData = options.variables.filter((v: any) => {
        if ((v.sort.Tooltip_only === "") && (v.sort["Shape Role"] !== "one col")) {
          return { v };
        }
      });
      const _tooltipData = options.variables.filter((v: any) => v.sort.Tooltip_only !== "");
      console.log("E112624_filteredData", _filteredData)
      console.log("E112624_tooltipData", _tooltipData)
      let ageGroupsArray = Array.from(new Set<string>(
        _filteredData.map((v: any) => {
          let vlabel = v.label.split("(")[0].trim();
          vlabel = vlabel.replace(/(Females|Males|\(%\))/g, '').trim()
          vlabel = vlabel.replace(/(Female|Male|\(%\))/g, '').trim()
          vlabel = vlabel.replace("  ", " ").trim()
          return vlabel
        })
      )) as string[];

      const under5Index = ageGroupsArray.indexOf("Under 5");
      const fiveTo14Index = ageGroupsArray.indexOf("5-14");

      if ((under5Index !== -1) && (fiveTo14Index !== -1)) {
        if (under5Index !== -1) {
          ageGroupsArray.splice(under5Index, 1);
        }
        if (fiveTo14Index !== -1) {
          ageGroupsArray.splice(fiveTo14Index > under5Index ? fiveTo14Index - 1 : fiveTo14Index, 1);
        }

        ageGroupsArray.unshift("Under 5", "5-14");
      }
      ageGroupsArray = ageGroupsArray.reverse();
      setAgeGroups(ageGroupsArray);

      let globalMaxFemale = 0;
      let globalMaxMale = 0;
      //console.log("EE112624 ageGroupsArray", ageGroupsArray)
      //console.log("EE112624 _filteredData", _filteredData)
      ageGroupsArray.forEach((ageGroup) => {
        let _ageGroup = ageGroup;
        if (ageGroup.includes("Registered Voter")) {
          _ageGroup = "Registered";
        }
        let femaleData = _filteredData.find((v: any) => v.label.includes('Females') && v.label.includes(ageGroup));
        let maleData = _filteredData.find((v: any) => v.label.includes('Males') && v.label.includes(ageGroup));
        femaleData = femaleData ? femaleData : _filteredData.find((v: any) => v.label.includes('Female') && v.label.includes(_ageGroup));
        maleData = maleData ? maleData : _filteredData.find((v: any) => v.label.includes('Male') && v.label.includes(_ageGroup));
        //console.log("EE112624 femaleData", femaleData)
        //console.log("EE112624 maleData", maleData)
        if (femaleData) {
          const femaleValue = data.dataJSON?.[selectedId]?.[femaleData.variable] ?? 0;
          const maxFemaleValue = data.meta?.max_value?.[femaleData.variable] ?? 0;
          globalMaxFemale = Math.max(globalMaxFemale, maxFemaleValue);
          let ttVariable = '';
          _tooltipData.forEach((s: any) => {
            if (s.sort.Tooltip_only === femaleData.variable) {
              ttVariable = s.variable;
            }
          });
          const tooltip = data.dataJSON[selectedId][ttVariable];
          const presentableTooltip = tooltip 
            ? d2gRound(tooltip, { DESCRIPTOR: data.meta["DESCRIPTOR"][ttVariable] }) 
            : d2gRound(femaleValue, { DESCRIPTOR: data.meta["DESCRIPTOR"][femaleData.variable] }) 
  
          const presentableLabel = tooltip 
            ? data.meta["DISPLAY_NAME"][ttVariable].split("(")[0]
            : data.meta["DISPLAY_NAME"][femaleData.variable].split("(")[0] 
          
          //console.log("D111824 ttVariable", ttVariable)
          //console.log("E112624 data.meta.na_count", data.meta.na_count)
          console.log("A012525 femaleData.variable", femaleData.variable)
          //console.log("EE112624 data.meta.na_count[femaleData.variable]", data.meta.na_count[femaleData.variable])
          combinedData.push({
            name: ageGroup,
            value: femaleValue,
            avg: data.meta?.avg?.[femaleData.variable] ?? 0,
            category: 'Female',
            tooltip: presentableTooltip,
            tooltipLabel: presentableLabel,
            variable: femaleData.variable,
            //tooltip: `${d2gRound(femaleValue, { DESCRIPTOR: data.meta["DESCRIPTOR"][femaleData.variable] })}`,
            //tooltipLabel: data.meta["DISPLAY_NAME"][femaleData.variable].split("(")[0],
            index: data.keyIndexDataArray[femaleData.variable]?.[selectedId]?.index ?? 0,
            na: data.meta.na_count[femaleData.variable],
            len: data.dataArray[femaleData.variable]?.length ?? 0,
            prov: data.meta.provenance[femaleData.variable] !== "NA" ? true : false,
          });
        }

        if (maleData) {
          const maleValue = data.dataJSON?.[selectedId]?.[maleData.variable] ?? 0;
          const maxMaleValue = data.meta?.max_value?.[maleData.variable] ?? 0;
          globalMaxMale = Math.max(globalMaxMale, maxMaleValue);
          let ttVariable = '';
          _tooltipData.forEach((s: any) => {
            if (s.sort.Tooltip_only === maleData.variable) {
              ttVariable = s.variable;
            }
          });
          const tooltip = data.dataJSON[selectedId][ttVariable];
          const presentableTooltip = tooltip 
            ? d2gRound(tooltip, { DESCRIPTOR: data.meta["DESCRIPTOR"][ttVariable] }) 
            : d2gRound(maleValue, { DESCRIPTOR: data.meta["DESCRIPTOR"][maleData.variable] }) 
  
          const presentableLabel = tooltip 
            ? data.meta["DISPLAY_NAME"][ttVariable].split("(")[0]
            : data.meta["DISPLAY_NAME"][maleData.variable].split("(")[0] 
          
          //console.log("D111824 ttVariable", ttVariable)
          //console.log("E112624 data.meta.na_count", data.meta.na_count)
          console.log("A012525 maleData.variable", maleData.variable)
          //data.meta.provenance[v.variable] !== "NA"
          //console.log("EE112624 data.meta.na_count[maleData.variable]", data.meta.na_count[maleData.variable])
          combinedData.push({
            name: ageGroup,
            value: maleValue,
            avg: data.meta?.avg?.[maleData.variable] ?? 0,
            category: 'Male',
            variable: maleData.variable,
            tooltip: presentableTooltip,
            tooltipLabel: presentableLabel,
            //tooltip: `${d2gRound(maleValue, { DESCRIPTOR: data.meta["DESCRIPTOR"][maleData.variable] })}`,
            //tooltipLabel: data.meta["DISPLAY_NAME"][maleData.variable].split("(")[0],
            index: data.keyIndexDataArray[maleData.variable]?.[selectedId]?.index ?? 0,
            na: data.meta.na_count[maleData.variable],
            len: data.dataArray[maleData.variable]?.length ?? 0,
            prov: data.meta.provenance[maleData.variable] !== "NA" ? true : false,
          });
        }
      });
      //console.log("F112624 combinedData", combinedData)
      setPreppedData(combinedData);
      setMaxFemaleValue(globalMaxFemale);
      setMaxMaleValue(globalMaxMale);
    }
  };

  const barScaleFemale = (value: number) => (value / maxFemaleValue) * 50;
  const barScaleMale = (value: number) => (value / maxMaleValue) * 50;

  const onClicked = (variable: string) => {
    if (activePage === "explorer") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    }else{
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  const handleMouseEnter = (event: React.MouseEvent<SVGRectElement>, obj: any) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const { clientX, clientY } = event;
      setTooltip({
        x: clientX - rect.left,
        y: clientY - rect.top,
        obj,
      });
    }
  };

  const handleMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const { clientX, clientY } = event;
      setTooltip((prev) => prev && {
        ...prev,
        x: clientX - rect.left,
        y: clientY - rect.top,
      });
    }
  };

  const handleMouseLeave = () => {
    setTooltip(null);
  };

  const fontSize2 = 12;

  useEffect(() => {
    if (svgRef.current && preppedData.length > 0) {
      const svg = d3.select<SVGSVGElement, unknown>(svgRef.current);

      const groups = svg.selectAll<SVGGElement, string>('.age-group')
        .data(ageGroups, (d: string) => d)
        .join(
          (enter) => enter
            .append('g')
            .attr('class', 'age-group')
            .attr('transform', (d, i) => `translate(0, ${i * (barHeight + 10)})`),
          (update) => update.attr('transform', (d, i) => `translate(0, ${i * (barHeight + 10)})`),
          (exit) => exit.remove()
        );

      groups.each(function (ageGroup) {
        const group = d3.select<SVGGElement, string>(this);
        const femaleData = preppedData.find((d: any) => d.category === 'Female' && d.name === ageGroup);
        const maleData = preppedData.find((d: any) => d.category === 'Male' && d.name === ageGroup);

        group.selectAll('.bar-female')
          .data([femaleData])
          .join(
            (enter) => enter
              .append('rect')
              .attr('class', 'bar-female')
              .attr('x', '50%')
              .attr('y', 0)
              .attr('height', barHeight)
              .attr('width', 0)
              .attr('fill', (d) => Colors.getColorQuintile(d?.index, d?.na, d?.len, colorClass))
              .style('cursor', 'pointer')
              .on('click', (event, d) => onClicked(d?.variable))
              .on('mouseenter', (event, d) => handleMouseEnter(event, d))
              .on('mousemove', handleMouseMove)
              .on('mouseleave', handleMouseLeave)
              .call((enter) => enter.transition().duration(750)
                .attr('x', (d) => `${50 - barScaleFemale(d?.value || 0)}%`)
                .attr('width', (d) => `${barScaleFemale(d?.value || 0)}%`)
              ),
            (update) => update
              .call((update) => update.transition().duration(750)
                .attr('x', (d) => `${50 - barScaleFemale(d?.value || 0)}%`)
                .attr('width', (d) => `${barScaleFemale(d?.value || 0)}%`)
                .attr('fill', (d) => Colors.getColorQuintile(d?.index, d?.na, d?.len, colorClass))
              ),
            (exit) => exit.remove()
          );

        group.selectAll('.bar-male')
          .data([maleData])
          .join(
            (enter) => enter
              .append('rect')
              .attr('class', 'bar-male')
              .attr('x', '50%')
              .attr('y', 0)
              .attr('height', barHeight)
              .attr('width', 0)
              .attr('fill', (d) => Colors.getColorQuintile(d?.index, d?.na, d?.len, colorClass))
              .style('cursor', 'pointer')
              .on('click', (event, d) => onClicked(d?.variable))
              .on('mouseenter', (event, d) => handleMouseEnter(event, d))
              .on('mousemove', handleMouseMove)
              .on('mouseleave', handleMouseLeave)
              .call((enter) => enter.transition().duration(750)
                .attr('width', (d) => `${barScaleMale(d?.value || 0)}%`)
              ),
            (update) => update
              .call((update) => update.transition().duration(750)
                .attr('width', (d) => `${barScaleMale(d?.value || 0)}%`)
                .attr('fill', (d) => Colors.getColorQuintile(d?.index, d?.na, d?.len, colorClass))
              ),
            (exit) => exit.remove()
          );

        // Add Average Lines
        group.selectAll('.avg-line-female')
          .data([femaleData])
          .join(
            (enter) => enter
              .append('line')
              .attr('class', 'avg-line-female')
              .attr('x1', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)
              .attr('x2', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)
              .attr('y1', 0)
              .attr('y2', barHeight)
              .attr('stroke', '#9E9C9C')
              .attr('stroke-dasharray', '4 2'),
            (update) => update
              .call((update) => update.transition().duration(750)
                .attr('x1', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)
                .attr('x2', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)),
            (exit) => exit.remove()
          );

        group.selectAll('.avg-line-male')
          .data([maleData])
          .join(
            (enter) => enter
              .append('line')
              .attr('class', 'avg-line-male')
              .attr('x1', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)
              .attr('x2', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)
              .attr('y1', 0)
              .attr('y2', barHeight)
              .attr('stroke', '#9E9C9C')
              .attr('stroke-dasharray', '4 2'),
            (update) => update
              .call((update) => update.transition().duration(750)
                .attr('x1', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)
                .attr('x2', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)),
            (exit) => exit.remove()
          );
      });
    }
  }, [preppedData, maxFemaleValue, maxMaleValue, barHeight, colorClass, ageGroups]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', paddingRight: "16px", position: 'relative' }}>
      {/* Left Info Section 
      <div style={{ marginLeft: '0px', marginRight: '0px', fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, width: '250px' }}>
        <p style={{ marginRight: "15px" }}>
          Other information about this stat including the year of origin and relevant notes and maybe an assessment of how typical this percentage is.
          <br /><span style={{ color: '#ccc' }}>Source: Stats United</span>
        </p>
      </div>*/}

      {/* Age Group Labels */}
      <div style={{ width: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: `${fontSize2}px`, paddingLeft: '5px' }}>
        {ageGroups.map((ageGroup: string, i: number) => {
          const femaleData = preppedData.find((d: any) => d.category === 'Female' && d.name === ageGroup);
          const maleData = preppedData.find((d: any) => d.category === 'Male' && d.name === ageGroup);
          return (
            <div key={i} style={{ width: '100%', display: 'flex', alignItems: 'center', height: `${barHeight + 10}px` }}>
              <div style={{ width: activePage === "dashboard" ? '40%' : '50%' , textAlign: 'left' }}>
                <strong>{d2gRound(femaleData?.value + maleData?.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][maleData?.variable] })}</strong>
              </div>
              <div style={{ width: activePage === "dashboard" ? '60%' : '50%', textAlign: 'left', paddingLeft: '5px', lineHeight:'1' }}>
                {ageGroup.replace("Registered Voter, ", "")}
                { (data.meta.provenance[maleData.variable] !== "NA" || data.meta.provenance[femaleData.variable] !== "NA") && activePage === "dashboard"
                    ? <span
                      style={{ 
                        color:"#F26631",
                      }}
                    > *</span> 
                    : null
                  }
              </div>
            </div>
          );
        })}
      </div>
      {/* Centered Pyramid */}
      <div style={{ marginRight: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', position: 'relative' }}>
        <div style={{ position: 'absolute', left: '10%', top: '50%', transform: 'translate(-50%, -50%) rotate(-90deg)', fontSize: `${fontSize2 + 2}px` }}>Female</div>
        <div style={{ position: 'absolute', right: '10%', top: '50%', transform: 'translate(50%, -50%) rotate(90deg)', fontSize: `${fontSize2 + 2}px` }}>Male</div>
        <div style={{ width: '2px', height: `${(ageGroups.length * (barHeight + 10) - 10)}px`, backgroundColor: 'black', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}></div>
        <svg ref={svgRef} width="100%" height={(ageGroups.length * (barHeight + 10) - 10)}></svg>
      </div>
      {/* Tooltip Section */}
      {tooltip && (
        <div
          style={{
            position: 'absolute',
            top: tooltip.y - 52.5,
            left: tooltip.x - 75,
            backgroundColor: '#fff',
            border: '2px solid #ccc',
            borderRadius: '6px',
            padding: '8px 12px',
            pointerEvents: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
            zIndex: 10,
            color: '#333',
            width: '150px',
            textAlign: 'left',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            maxWidth: '150px',
            lineHeight: '0.9',
          }}
        >
            <b>{tooltip.obj.tooltip}</b> <span style={{
              fontSize: '10px',
            }}>{tooltip.obj.tooltipLabel}</span>
            <div
              style={{
                position: 'absolute',
                bottom: '-7px', // Lower the notch below tooltip
                left: '45%', // Center horizontally under the tooltip content
                width: '12px', // Adjust width for the callout notch
                height: '12px',
                backgroundColor: '#fff', // Same as tooltip background
                borderLeft: '2px solid #ccc',
                borderBottom: '2px solid #ccc',
                transform: 'rotate(-45deg)', // Rotate for triangle effect
                boxShadow: '-1px 1px 2px rgba(0, 0, 0, 0.1)', // Same shadow
              }}
            />
        </div>
      )}
    </div>
  );
};

export default Pyramid;
