import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import Colors from '../utilities/Colors';
import { useGlobalHoveredId, useGlobalSelectedId } from '../../data/StatusStore';
import { onHoverHistogram, onClickHistogram, onSetBivariateOverRide, useGlobalBivariateOverRide } from '../../data/StatusStore';
import { getInvertedBivariateColorBreaks, d2gRound } from '../utilities/Utilities';

interface DataItem {
  id: string;
  value: number;
  index: number;
}

export interface ScatterPlotProps {
  dataArrayX: Record<string, DataItem>;
  dataArrayY: Record<string, DataItem>;
  dataMeta: any;
  histXMax: number;
  histXMin: number;
  histXNA: number;
  histXLength: number;
  histYMax: number;
  histYMin: number;
  histYNA: number;
  histYLength: number;
  chartId: string;
  height: string;
  width: string;
  activeIndicator: string;
  bivariateIndicator: string;
  mapType: string;
  allData: any;
  radius: number;
  handleChildReady: Function;
}

const ScatterPlot: React.FC<ScatterPlotProps> = ({
  dataArrayX, dataArrayY, dataMeta, allData,
  histXMax, histXMin, histXNA, histXLength,
  histYMax, histYMin, histYNA, histYLength,
  chartId, height, width,
  activeIndicator, bivariateIndicator, mapType, radius, handleChildReady
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId();

  const [breaks, setBreaks] = useState<{ xBreaks: number[], yBreaks: number[] }>({ xBreaks: [], yBreaks: [] });
  const [lockDrag, setLockDrag] = useState<boolean>(false);
  const [persistableSelectedId, setPersistableSelectedId] = useState<string | null>(null);
  const bivariateOverRide = useGlobalBivariateOverRide();

  useEffect(() => {
    //console.log("A113024 selectedId",selectedId);
    //handleChildReady();
    //console.log("060524 <--- selectedId",selectedId);
    //console.log("A113024 <--- persistableSelectedId",persistableSelectedId);
    //console.log("060524 <--- activeGeography",activeGeography);
    //console.log("101124 calling  handleChildReady ");
    handleChildReady();
    if (selectedId) {
      setPersistableSelectedId(selectedId);
    }  
  }, [selectedId]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (!entries || entries.length === 0) {
        return;
      }
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    console.log("D071624 bivariateOverRide", bivariateOverRide);
    console.log("D071624 breaks", breaks);
  }, [bivariateOverRide]);

  //useEffect(() => {
    //console.log("A113024 selectedId", selectedId);
    //console.log("A113024 hoveredId", hoveredId);
  //}, [selectedId, hoveredId]);

  useEffect(() => {
    onSetBivariateOverRide({ x: breaks.xBreaks, y: breaks.yBreaks });
  }, [breaks]);

  useEffect(() => {
    if (dataArrayX && dataArrayY) {
      const _breaks = getInvertedBivariateColorBreaks(
        histXMax, histXMin, histXLength, dataArrayX,
        histYMax, histYMin, histYLength, dataArrayY,
        allData, bivariateIndicator, activeIndicator
      );

      setBreaks(_breaks);
      onSetBivariateOverRide({ x: _breaks.xBreaks, y: _breaks.yBreaks });
    }
  }, [dataArrayX, dataArrayY, activeIndicator, bivariateIndicator]);

  useEffect(() => {
    if (dataArrayX && dataArrayY && svgRef.current && dimensions.width && dimensions.height) {
      // Combine data
      const data = Object.keys(dataArrayX).map(key => ({
        id: key,
        xStr: typeof dataArrayX[key].value === 'number' ? dataArrayX[key].value : "NA",
        x: typeof dataArrayX[key].value === 'number' ? dataArrayX[key].value : 0,
        xIndex: dataArrayX[key].index,
        yStr: typeof dataArrayY[key].value === 'number' ? dataArrayY[key].value : "NA",
        y: typeof dataArrayY[key].value === 'number' ? dataArrayY[key].value : 0,
        yIndex: dataArrayY[key].index,
      }));

      const svg = svgRef.current;
      const margin = { top: 20, right: 20, bottom: 130, left: 40 };
      const svgWidth = dimensions.width - margin.left - margin.right;
      const svgHeight = dimensions.height - margin.top - margin.bottom;

      svg.innerHTML = ''; // Clear previous SVG content

      // Create the main group element
      const mainGroup = d3.select(svg)
        .attr('width', svgWidth + margin.left + margin.right)
        .attr('height', svgHeight + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Create scales
      const x = d3.scaleLinear()
        .domain([d3.min(data, d => d.x) || 0, d3.max(data, d => d.x) || 100])
        .range([0, svgWidth]);

      const y = d3.scaleLinear()
        .domain([d3.min(data, d => d.y) || 0, d3.max(data, d => d.y) || 100])
        .range([svgHeight, 0]);

      // Add X axis
      mainGroup.append('g')
        .attr('transform', `translate(0,${svgHeight})`)
        .call(d3.axisBottom(x));

      // Add Y axis
      mainGroup.append('g')
        .call(d3.axisLeft(y));

      // Add dots with hover, selection, etc.
      let dataHover = null;
      if (hoveredId && dataArrayX[hoveredId] && dataArrayY[hoveredId] && hoveredId !== selectedId) { 
        dataHover = {
          id: hoveredId,
          xStr: typeof dataArrayX[hoveredId].value === 'number' ? dataArrayX[hoveredId].value : "NA",
          x: typeof dataArrayX[hoveredId].value === 'number' ? dataArrayX[hoveredId].value : 0,
          xIndex: dataArrayX[hoveredId].index,
          yStr: typeof dataArrayY[hoveredId].value === 'number' ? dataArrayY[hoveredId].value : "NA",
          y: typeof dataArrayY[hoveredId].value === 'number' ? dataArrayY[hoveredId].value : 0,
          yIndex: dataArrayY[hoveredId].index,
        };
      }

      let dataSelect = null;
      if (selectedId && dataArrayX[selectedId] && dataArrayY[selectedId]) {
        dataSelect = {
          id: selectedId,
          xStr: typeof dataArrayX[selectedId].value === 'number' ? dataArrayX[selectedId].value : "NA",
          x: typeof dataArrayX[selectedId].value === 'number' ? dataArrayX[selectedId].value : 0,
          xIndex: dataArrayX[selectedId].index,
          yStr: typeof dataArrayY[selectedId].value === 'number' ? dataArrayY[selectedId].value : "NA",
          y: typeof dataArrayY[selectedId].value === 'number' ? dataArrayY[selectedId].value : 0,
          yIndex: dataArrayY[selectedId].index,
        };
      }
      const dataHoverSelect = [];
    
      if (dataHover) {
        dataHoverSelect.push(dataHover);
      }
      if (dataSelect) {
        dataHoverSelect.push(dataSelect);
      }

      // Draw circles
      if (dataMeta && dataMeta.na_count) {
        let na_countActive = dataMeta?.na_count[activeIndicator];
        let na_countBivariate = dataMeta?.na_count[bivariateIndicator];
        const circles = mainGroup.append('g')
          .selectAll('circle')
          .data(data)
          .enter()
          .append('circle')
          .attr('cx', d => x(d.x))
          .attr('cy', d => y(d.y))
          .attr('r', radius)
          .attr('cursor', 'pointer')
          .style('fill', d => mapType === "bivariate - inverted"
            ? Colors.getInvertedBivariateColor(
              "scatterplot",
              d.id,
              d.xIndex, 
              na_countActive, 
              histXLength, 
              d.yIndex, 
              na_countBivariate, 
              histYLength, 
              bivariateOverRide, 
              d.yStr, 
              d.xStr)
            : Colors.getStandardBivariateColor(d.xIndex, na_countActive, histXLength, d.yIndex, na_countBivariate, histYLength))
          .attr('class', d => d.id == selectedId ? 'selected' : d.id == hoveredId ? 'hovered' : '')
          .style('pointer-events', lockDrag ? 'none' : 'auto')
          .on('mouseover', function (event, d) {
            if (!lockDrag) {
              d3.select(this).attr('r', 6);
              onHoverHistogram(d.id);
            }
          })
          .on('mouseout', function (event, d) {
            if (!lockDrag) {
              d3.select(this).attr('r', 3);
              onHoverHistogram(null);
            }
          })
          .on('click', function (event, d) {
            if (!lockDrag) {
              onClickHistogram(d.id);
            }
          });

        // Update circles on hover/selection changes
        circles.attr('class', d => d.id == selectedId ? 'selected' : d.id == hoveredId ? 'hovered' : '');

        // Create drag behaviors
        const createDragBehavior = (axis: 'x' | 'y', index: number) => {
          let _breaks = breaks;
          return d3.drag<SVGPathElement, unknown>()
            .on('start', function (event) {
              setLockDrag(true);
              d3.selectAll('circle').style('pointer-events', 'none');
              d3.select(this).raise().attr('stroke', 'black');
            })
            .on('drag', function (event) {
              if (axis === 'x') {
                console.log("072924 index", index)
                let newX = x.invert(event.x);
                newX = newX > 100 ? 100 : newX;
                newX = newX < 0 ? 0 : newX;
                if (index === 0){
                  newX = newX <= _breaks.yBreaks[1] ? newX :  _breaks.yBreaks[1];
                } else {
                  newX = newX >= _breaks.yBreaks[0] ? newX :  _breaks.yBreaks[1];
                }
                console.log("072924 newX", newX)
                _breaks.yBreaks[index] = newX;
                d3.select(this).attr('transform', `translate(${x(newX)}, ${svgHeight + 10}) rotate(0)`);
                mainGroup.select(`#x-line-${index}`).attr('x1', x(newX)).attr('x2', x(newX));
              } else {
                console.log("072924 index", index)
                let newY = y.invert(event.y);
                newY = newY > 100 ? 100 : newY;
                newY = newY < 0 ? 0 : newY;
                if (index === 0){
                  newY = newY <= _breaks.xBreaks[1] ? newY :  _breaks.xBreaks[1];
                } else {
                  newY = newY >= _breaks.xBreaks[0] ? newY :  _breaks.xBreaks[1];
                }
                console.log("072924 newY", newY)
                _breaks.xBreaks[index] = newY;
                d3.select(this).attr('transform', `translate(-10, ${y(newY)}) rotate(90)`);
                mainGroup.select(`#y-line-${index}`).attr('y1', y(newY)).attr('y2', y(newY));
              }
              setBreaks(_breaks);
            })
            .on('end', function (event) {
              d3.select(this).attr('stroke', null);
              onSetBivariateOverRide({ x: _breaks.xBreaks, y: _breaks.yBreaks });
              setLockDrag(false);
              d3.selectAll('circle').style('pointer-events', 'auto');
            });
        };

        // Add draggable lines/triangles for X axis
        breaks.yBreaks.forEach((breakPoint, index) => {
          mainGroup.append('line')
            .attr('id', `x-line-${index}`)
            .attr('x1', x(breakPoint))
            .attr('x2', x(breakPoint))
            .attr('y1', 0)
            .attr('y2', svgHeight)
            .attr('stroke', '#cccccc')
            .attr('stroke-width', 1);

          mainGroup.append('path')
            .attr('d', d3.symbol().type(d3.symbolTriangle).size(128)())
            .attr('fill', '#cccccc')
            .attr('transform', `translate(${x(breakPoint)}, ${svgHeight + 10}) rotate(0)`)
            //.attr('cursor', 'pointer')
            //.call(createDragBehavior('x', index))
            .attr("opacity", 0.75);
        });

        // Add draggable lines/triangles for Y axis
        breaks.xBreaks.forEach((breakPoint, index) => {
          mainGroup.append('line')
            .attr('id', `y-line-${index}`)
            .attr('x1', 0)
            .attr('x2', svgWidth)
            .attr('y1', y(breakPoint))
            .attr('y2', y(breakPoint))
            .attr('stroke', '#cccccc')
            .attr('stroke-width', 1);

          mainGroup.append('path')
            .attr('d', d3.symbol().type(d3.symbolTriangle).size(128)())
            .attr('fill', '#cccccc')
            .attr('transform', `translate(-10, ${y(breakPoint)}) rotate(90)`)
            //.attr('cursor', 'pointer')
            //.call(createDragBehavior('y', index))
            .attr("opacity", 0.75);
        });

        // Circles for hover/select
        const circlesHoverSelect = mainGroup.append('g')
          .selectAll('circle')
          .data(dataHoverSelect)
          .enter()
          .append('circle')
          .attr('cx', d => x(d.x))
          .attr('cy', d => y(d.y))
          .attr('r', 6)
          .attr('cursor', 'pointer')
          .style('fill', d => mapType === "bivariate - inverted"
            ? Colors.getInvertedBivariateColor(
                      "scatterplot fill", 
                      d.id, 
                      d.xIndex, 
                      na_countActive, 
                      histXLength, 
                      d.yIndex, 
                      na_countBivariate, 
                      histYLength,
                      bivariateOverRide, 
                      d.yStr, 
                      d.xStr
                    )
            : Colors.getStandardBivariateColor(d.xIndex, na_countActive, histXLength, d.yIndex, na_countBivariate, histYLength))
          .style('stroke-width', 3)
          .style('stroke', d => d.id == selectedId
            ? Colors.highlightSelect
            : d.id == hoveredId
              ? Colors.highlightHover
              : mapType === "bivariate - inverted"
                ? Colors.getInvertedBivariateColor(
                      "scatterplot stroke", 
                      d.id, 
                      d.xIndex, 
                      na_countActive, 
                      histXLength, 
                      d.yIndex, 
                      na_countBivariate, 
                      histYLength,
                      bivariateOverRide, 
                      d.yStr, 
                      d.xStr
                    )
                : Colors.getStandardBivariateColor(d.xIndex, na_countActive, histXLength, d.yIndex, na_countBivariate, histYLength))
          .attr('class', d => d.id == selectedId ? 'selected' : d.id == hoveredId ? 'hovered' : '')
          .style('pointer-events', lockDrag ? 'none' : 'auto')
          .on('mouseover', function (event, d) {
            if (!lockDrag) {
              d3.select(this).attr('r', 6);
              onHoverHistogram(d.id);
            }
          })
          .on('mouseout', function (event, d) {
            if (!lockDrag) {
              d3.select(this).attr('r', 3);
              onHoverHistogram(null);
            }
          })
          .on('click', function (event, d) {
            if (!lockDrag) {
              onClickHistogram(d.id);
            }
          });


        //
        // -- OLD LEFT/RIGHT POLYGONS (COMMENTED OUT) --
        //
        // const polygonsHoverSelectRight = mainGroup.append('g')
        //   .selectAll('polygon')
        //   .data(dataHoverSelect.filter(d => x(d.x) <= svgWidth / 2))
        //   .enter()
        //   .append('polygon')
        //   .attr('points', d => { ... })
        //   .attr('fill', 'white')
        //   .attr('stroke', 'black')
        //   .attr('stroke-width', 1)
        //   .attr('opacity', 0.75);
        //
        // const textHoverSelectRight = mainGroup.append('g')
        //   .selectAll('text')
        //   .data(dataHoverSelect.filter(d => x(d.x) <= svgWidth / 2))
        //   .enter()
        //   .append('text')
        //   .attr('x', d => x(d.x) + 22)
        //   .attr('y', d => y(d.y))
        //   .attr('dy', '.35em')
        //   .attr('text-anchor', 'left')
        //   .attr('font-size', '10px')
        //   .text(d => ... );
        //
        // const polygonsHoverSelectLeft = mainGroup.append('g')
        //   .selectAll('polygon')
        //   .data(dataHoverSelect.filter(d => x(d.x) > svgWidth / 2))
        //   .enter()
        //   .append('polygon')
        //   .attr('points', d => { ... })
        //   .attr('fill', 'white')
        //   .attr('stroke', 'black')
        //   .attr('stroke-width', 1)
        //   .attr('opacity', 0.75);
        //
        // const textHoverSelectLeft = mainGroup.append('g')
        //   .selectAll('text')
        //   .data(dataHoverSelect.filter(d => x(d.x) > svgWidth / 2))
        //   .enter()
        //   .append('text')
        //   .attr('x', d => x(d.x) - 42)
        //   .attr('y', d => y(d.y))
        //   .attr('dy', '.35em')
        //   .attr('text-anchor', 'right')
        //   .attr('font-size', '10px')
        //   .text(d => ... );


        //
        // -- NEW RED LEFT/RIGHT TOOLTIP SHAPES WITH ROUNDED CORNERS --
        //
        const halfHeight = 12.5;
        const arrowOffset = 8;
        const arrowWidth = 10;
        const cornerRadius = 5;

        /**
         * RIGHT side bubble (red) with small Q (quadratic) commands for rounded corners.
         */
        const bubblePathRight = (d: any) => {
          const x0 = x(d.x);
          const y0 = y(d.y);
          const textLength =
            d.xStr == "NA"
              ? 2
              : d2gRound(d.x, { DESCRIPTOR: dataMeta["DESCRIPTOR"][activeIndicator] }).toString().length;
          const rectWidth = 8 * textLength + 30;
          const r = cornerRadius;
          return `
            M${x0 + arrowOffset},${y0}
            L${x0 + arrowOffset + arrowWidth},${y0 - (halfHeight - r)}
            Q${x0 + arrowOffset + arrowWidth},${y0 - halfHeight},${x0 + arrowOffset + arrowWidth + r},${y0 - halfHeight}
            L${x0 + arrowOffset + rectWidth - r},${y0 - halfHeight}
            Q${x0 + arrowOffset + rectWidth},${y0 - halfHeight},${x0 + arrowOffset + rectWidth},${y0 - halfHeight + r}
            L${x0 + arrowOffset + rectWidth},${y0 + halfHeight - r}
            Q${x0 + arrowOffset + rectWidth},${y0 + halfHeight},${x0 + arrowOffset + rectWidth - r},${y0 + halfHeight}
            L${x0 + arrowOffset + arrowWidth + r},${y0 + halfHeight}
            Q${x0 + arrowOffset + arrowWidth},${y0 + halfHeight},${x0 + arrowOffset + arrowWidth},${y0 + halfHeight - r}
            L${x0 + arrowOffset + arrowWidth},${y0 + (halfHeight - r)}
            Z
          `;
        };

        /**
         * LEFT side bubble (red) with small Q commands for rounded corners.
         */
        const bubblePathLeft = (d: any) => {
          const x0 = x(d.x);
          const y0 = y(d.y);
          const textLength =
            d.xStr == "NA"
              ? 2
              : d2gRound(d.x, { DESCRIPTOR: dataMeta["DESCRIPTOR"][activeIndicator] }).toString().length;
          const rectWidth = 8 * textLength + 30;
          const r = cornerRadius;
          return `
            M${x0 - arrowOffset},${y0}
            L${x0 - arrowOffset - arrowWidth},${y0 - (halfHeight - r)}
            Q${x0 - arrowOffset - arrowWidth},${y0 - halfHeight},${x0 - arrowOffset - arrowWidth - r},${y0 - halfHeight}
            L${x0 - arrowOffset - rectWidth + r},${y0 - halfHeight}
            Q${x0 - arrowOffset - rectWidth},${y0 - halfHeight},${x0 - arrowOffset - rectWidth},${y0 - halfHeight + r}
            L${x0 - arrowOffset - rectWidth},${y0 + halfHeight - r}
            Q${x0 - arrowOffset - rectWidth},${y0 + halfHeight},${x0 - arrowOffset - rectWidth + r},${y0 + halfHeight}
            L${x0 - arrowOffset - arrowWidth - r},${y0 + halfHeight}
            Q${x0 - arrowOffset - arrowWidth},${y0 + halfHeight},${x0 - arrowOffset - arrowWidth},${y0 + halfHeight - r}
            L${x0 - arrowOffset - arrowWidth},${y0 + (halfHeight - r)}
            Z
          `;
        };

        // Draw new path-based red tooltip on the RIGHT side
        const polygonsHoverSelectRight = mainGroup
          .append("g")
          .selectAll("path")
          .data(dataHoverSelect.filter(d => x(d.x) <= svgWidth / 2))
          .enter()
          .append("path")
          .attr("d", d => bubblePathRight(d))
          //.attr("fill", "#CC4554")
          .attr("fill", Colors.bivariateColorScheme[2][2])
          //.attr("stroke", "#999999")
          .attr("stroke", Colors.bivariateColorScheme[2][2])
          .attr("stroke-width", 2)
          .attr("opacity", 0.85)
          .attr("stroke-opacity", 0.85);

        const textHoverSelectRight = mainGroup
          .append("g")
          .selectAll("text")
          .data(dataHoverSelect.filter(d => x(d.x) <= svgWidth / 2))
          .enter()
          .append("text")
          .attr("x", d => {
            const textLen =
              d.xStr === "NA"
                ? 2
                : d2gRound(d.x, { DESCRIPTOR: dataMeta["DESCRIPTOR"][activeIndicator] }).toString().length;
            const rectW = 8 * textLen + 30;
            return x(d.x) + arrowOffset + arrowWidth + rectW / 2;
          })
          .attr("y", d => y(d.y))
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "#000000")
          .text(d =>
            d.xStr == "NA"
              ? "NA"
              : d2gRound(d.x, { DESCRIPTOR: dataMeta["DESCRIPTOR"][activeIndicator] })
          );

        // Draw new path-based red tooltip on the LEFT side
        const polygonsHoverSelectLeft = mainGroup
          .append("g")
          .selectAll("path")
          .data(dataHoverSelect.filter(d => x(d.x) > svgWidth / 2))
          .enter()
          .append("path")
          .attr("d", d => bubblePathLeft(d))
          .attr("fill", Colors.bivariateColorScheme[2][2])
          //.attr("fill", "#CC4554")
          //.attr("stroke", "#999999")
          .attr("stroke", Colors.bivariateColorScheme[2][2])
          .attr("stroke-width", 2)
          .attr("opacity", 0.85)
          .attr("stroke-opacity", 0.85);

        const textHoverSelectLeft = mainGroup
          .append("g")
          .selectAll("text")
          .data(dataHoverSelect.filter(d => x(d.x) > svgWidth / 2))
          .enter()
          .append("text")
          .attr("x", d => {
            const textLen =
              d.xStr === "NA"
                ? 2
                : d2gRound(d.x, { DESCRIPTOR: dataMeta["DESCRIPTOR"][activeIndicator] }).toString().length;
            const rectW = 8 * textLen + 30;
            return x(d.x) - arrowOffset - arrowWidth - rectW / 2;
          })
          .attr("y", d => y(d.y))
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "#000000")
          .text(d =>
            d.xStr == "NA"
              ? "NA"
              : d2gRound(d.x, { DESCRIPTOR: dataMeta["DESCRIPTOR"][activeIndicator] })
          );


        //
        // -- OLD TOP/BOTTOM POLYGONS (COMMENTED OUT) --
        //
        // const polygonsHoverSelectTop = mainGroup.append('g')
        //   .selectAll('polygon')
        //   .data(dataHoverSelect.filter(d => y(d.y) > svgHeight / 2))
        //   .enter()
        //   .append('polygon')
        //   .attr('points', d => { ... });
        // ...
        // const polygonsHoverSelectBottom = mainGroup.append('g')
        //   .selectAll('polygon')
        //   .data(dataHoverSelect.filter(d => y(d.y) <= svgHeight / 2))
        //   .enter()
        //   .append('polygon')
        //   .attr('points', d => { ... });
        // ...
        // etc.


        //
        // -- NEW BLUE TOP/BOTTOM TOOLTIP SHAPES WITH ROUNDED CORNERS --
        //
        const halfHeightBlue = 12.5;  
        const arrowOffsetBlue = 8;
        const arrowWidthBlue = 10;

        /**
         * TOP bubble (blue)
         */
        const bubblePathTop = (d: any) => {
          const x0 = x(d.x);
          const y0 = y(d.y);
          const textLength =
            d.yStr == "NA"
              ? 2
              : d2gRound(d.y, { DESCRIPTOR: dataMeta["DESCRIPTOR"][bivariateIndicator] }).toString().length;
          const rectWidth = Math.max(50, 8 * textLength + 30);
          const r = 5; 
          let _halfHeight = halfHeightBlue * 1.25; 
          return `
            M${x0},${y0 - arrowOffsetBlue}
            L${x0 + arrowWidthBlue},${y0 - arrowOffsetBlue - arrowWidthBlue}
            L${x0 + rectWidth / 2 - r},${y0 - arrowOffsetBlue - arrowWidthBlue}
            Q${x0 + rectWidth / 2},${y0 - arrowOffsetBlue - arrowWidthBlue},${x0 + rectWidth / 2},${y0 - arrowOffsetBlue - arrowWidthBlue - r}
            L${x0 + rectWidth / 2},${y0 - arrowOffsetBlue - 2 * _halfHeight + r}
            Q${x0 + rectWidth / 2},${y0 - arrowOffsetBlue - 2 * _halfHeight},${x0 + rectWidth / 2 - r},${y0 - arrowOffsetBlue - 2 * _halfHeight}
            L${x0 - rectWidth / 2 + r},${y0 - arrowOffsetBlue - 2 * _halfHeight}
            Q${x0 - rectWidth / 2},${y0 - arrowOffsetBlue - 2 * _halfHeight},${x0 - rectWidth / 2},${y0 - arrowOffsetBlue - 2 * _halfHeight + r}
            L${x0 - rectWidth / 2},${y0 - arrowOffsetBlue - arrowWidthBlue - r}
            Q${x0 - rectWidth / 2},${y0 - arrowOffsetBlue - arrowWidthBlue},${x0 - rectWidth / 2 + r},${y0 - arrowOffsetBlue - arrowWidthBlue}
            L${x0 - arrowWidthBlue},${y0 - arrowOffsetBlue - arrowWidthBlue}
            Z
          `;
        };

        /**
         * BOTTOM bubble (blue)
         */
        const bubblePathBottom = (d: any) => {
          const x0 = x(d.x);
          const y0 = y(d.y);
          const textLength =
            d.yStr == "NA"
              ? 2
              : d2gRound(d.y, { DESCRIPTOR: dataMeta["DESCRIPTOR"][bivariateIndicator] }).toString().length;
          const rectWidth = Math.max(50, 8 * textLength + 30);
          const r = 5; 
          let _halfHeight = halfHeightBlue * 1.25;
          return `
            M${x0},${y0 + arrowOffsetBlue}
            L${x0 + arrowWidthBlue},${y0 + arrowOffsetBlue + arrowWidthBlue}
            L${x0 + rectWidth / 2 - r},${y0 + arrowOffsetBlue + arrowWidthBlue}
            Q${x0 + rectWidth / 2},${y0 + arrowOffsetBlue + arrowWidthBlue},${x0 + rectWidth / 2},${y0 + arrowOffsetBlue + arrowWidthBlue + r}
            L${x0 + rectWidth / 2},${y0 + arrowOffsetBlue + 2 * _halfHeight - r}
            Q${x0 + rectWidth / 2},${y0 + arrowOffsetBlue + 2 * _halfHeight},${x0 + rectWidth / 2 - r},${y0 + arrowOffsetBlue + 2 * _halfHeight}
            L${x0 - rectWidth / 2 + r},${y0 + arrowOffsetBlue + 2 * _halfHeight}
            Q${x0 - rectWidth / 2},${y0 + arrowOffsetBlue + 2 * _halfHeight},${x0 - rectWidth / 2},${y0 + arrowOffsetBlue + 2 * _halfHeight - r}
            L${x0 - rectWidth / 2},${y0 + arrowOffsetBlue + arrowWidthBlue + r}
            Q${x0 - rectWidth / 2},${y0 + arrowOffsetBlue + arrowWidthBlue},${x0 - rectWidth / 2 + r},${y0 + arrowOffsetBlue + arrowWidthBlue}
            L${x0 - arrowWidthBlue},${y0 + arrowOffsetBlue + arrowWidthBlue}
            Z
          `;
        };

        // Top bubble (blue)
        const polygonsHoverSelectTop = mainGroup
          .append("g")
          .selectAll("path")
          .data(dataHoverSelect.filter(d => y(d.y) > svgHeight / 2))
          .enter()
          .append("path")
          .attr("d", d => bubblePathTop(d))
          .attr("fill", Colors.bivariateColorScheme[0][2])
          //.attr("fill", "#5C8FCA")
          .attr("stroke", "#999999")
          .attr("stroke", Colors.bivariateColorScheme[0][2])
          .attr("stroke-width", 2)
          .attr("opacity", 0.85)
          .attr("stroke-opacity", 0.85);

        const textHoverSelectTop = mainGroup
          .append("g")
          .selectAll("text")
          .data(dataHoverSelect.filter(d => y(d.y) > svgHeight / 2))
          .enter()
          .append("text")
          .attr("x", d => x(d.x))
          .attr("y", d => y(d.y) - (arrowWidthBlue + halfHeightBlue + 5))
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "#000000")
          .text(d =>
            `${d.yStr == "NA"
              ? "NA"
              : d2gRound(d.y, { DESCRIPTOR: dataMeta["DESCRIPTOR"][bivariateIndicator] })}`
          );

        // Bottom bubble (blue)
        const polygonsHoverSelectBottom = mainGroup
          .append("g")
          .selectAll("path")
          .data(dataHoverSelect.filter(d => y(d.y) <= svgHeight / 2))
          .enter()
          .append("path")
          .attr("d", d => bubblePathBottom(d))
          .attr("fill", Colors.bivariateColorScheme[0][2])
          //.attr("fill", "#5C8FCA")
          //.attr("stroke", "#999999")
          .attr("stroke", Colors.bivariateColorScheme[0][2])
          .attr("stroke-width", 2)
          .attr("opacity", 0.85)
          .attr("stroke-opacity", 0.85);

        const textHoverSelectBottom = mainGroup
          .append("g")
          .selectAll("text")
          .data(dataHoverSelect.filter(d => y(d.y) <= svgHeight / 2))
          .enter()
          .append("text")
          .attr("x", d => x(d.x))
          .attr("y", d => y(d.y) + (arrowWidthBlue + halfHeightBlue + 5))
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "#000000")
          .text(d =>
            `${d.yStr == "NA"
              ? "NA"
              : d2gRound(d.y, { DESCRIPTOR: dataMeta["DESCRIPTOR"][bivariateIndicator] })}`
          );


        // Add axis labels
        mainGroup.append('text')
          .attr('x', svgWidth / 2)
          .attr('y', svgHeight + margin.top + 20)
          .style('text-anchor', 'middle')
          //.style('fill', '#CC4554') // Set the text color
          .style('fill', Colors.bivariateColorScheme[2][2]) // Set the text color
          .style('font-weight', 'bold')
          .attr("font-size", "14px")
          .text(dataMeta.DISPLAY_NAME[activeIndicator].split("(")[0])
          .append('tspan')
          .style('font-weight', 'normal')
          .text(dataMeta.DISPLAY_NAME[activeIndicator].split("(")[1] ? ` (${dataMeta.DISPLAY_NAME[activeIndicator].split("(")[1]}` : "");

        mainGroup.append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0 - margin.left)
          .attr('x', 0 - (svgHeight / 2))
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .style('fill', Colors.bivariateColorScheme[0][2]) // Set the text color
          //.style('fill', '#5C8FCA') // Set the text color
          .style('font-weight', 'bold')
          .attr("font-size", "14px")
          .text(dataMeta.DISPLAY_NAME[bivariateIndicator].split("(")[0])
          .append('tspan')
          .style('font-weight', 'normal')
          .text(dataMeta.DISPLAY_NAME[bivariateIndicator].split("(")[1] ? ` (${dataMeta.DISPLAY_NAME[bivariateIndicator].split("(")[1]}` : "");
      }
    }
  }, [
    dataArrayX, dataArrayY,
    dimensions.width, dimensions.height,
    activeIndicator, bivariateIndicator,
    hoveredId, selectedId,
    mapType, bivariateOverRide,
    radius
  ]);

  useEffect(() => {
    onSetBivariateOverRide(null);
  }, [dataArrayX, dataArrayY, activeIndicator, bivariateIndicator, mapType]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        preserveAspectRatio="xMidYMid meet"
      />
    </div>
  );
};

export default ScatterPlot;
