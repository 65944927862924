import React, { useState, useMemo, useEffect } from 'react';
import Colors from '../utilities/Colors';

export interface Indicator {
  id: string;
  name: string;
}

interface ModuleSearchProps {
  /** 
   * `allIndicators` is an object with a `DISPLAY_NAME` property, 
   * e.g. { DISPLAY_NAME: { 'key1': 'Val1', 'key2': 'Val2', ... } }
   */
  allIndicators: any;
  /**
   * Called when user clicks "Add".
   * Passes pinned indicators and the module name.
   */
  onAdd?: (pinnedIndicators: Indicator[], moduleName: string) => void;
  validateConfig: Function; //(pinnedIndicators: Indicator[], moduleName: string) => boolean;
  /** Called when user clicks "Cancel". */
  onCancel?: () => void;
}

const ModuleSearch: React.FC<ModuleSearchProps> = ({
  allIndicators,
  onAdd,
  validateConfig,
  onCancel,
}) => {
  // The user's custom module name
  const [moduleName, setModuleName] = useState<string>('');
  // The text in the search box
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [validated, setValidated] = useState<string>('');
  // Which indicators have been pinned (checked)
  const [pinnedIndicators, setPinnedIndicators] = useState<Indicator[]>([]);

  useEffect(() => {
    console.log('J011625 Module name changed:', moduleName);
    setValidated(validateConfig(pinnedIndicators, moduleName));
  }, [moduleName, pinnedIndicators]);
  /**
   * Helper to check if an indicator is already pinned
   */
  const isPinned = (indicator: Indicator) =>
    pinnedIndicators.some((p) => p.id === indicator.id);

  /**
   * Toggle pinned status on an indicator
   */
  const togglePinned = (indicator: Indicator) => {
    if (isPinned(indicator)) {
      // Unpin
      setPinnedIndicators((prev) => prev.filter((p) => p.id !== indicator.id));
    } else {
      // Pin
      setPinnedIndicators((prev) => [...prev, indicator]);
    }
  };

  /**
   * Transform allIndicators.DISPLAY_NAME into an array of { id, name }
   * This array does NOT go into state; we simply compute it via useMemo.
   */
  const allIndicatorsArray = useMemo(() => {
    return Object.keys(allIndicators.DISPLAY_NAME).map((key) => ({
      id: key,
      name: allIndicators.DISPLAY_NAME[key],
    }));
  }, [allIndicators]);

  /**
   * Filter out pinned indicators. 
   * If there's no search term, show all unpinned indicators.
   * If there is a search term, filter by it.
   */
  const filteredIndicators = useMemo(() => {
    const lowerSearch = searchTerm.toLowerCase().trim();
    if (!lowerSearch) {
      // No search term => show all unpinned
      return allIndicatorsArray.filter((ind) => !isPinned(ind));
    }
    // Search term => filter by name, exclude pinned
    return allIndicatorsArray.filter(
      (ind) => !isPinned(ind) && ind.name.toLowerCase().includes(lowerSearch)
    );
  }, [allIndicatorsArray, pinnedIndicators, searchTerm]);

  /**
   * Handle Add
   */
  const handleAdd = () => {
    if (onAdd) {
      onAdd(pinnedIndicators, moduleName);
    } else {
      console.log('onAdd not provided');
      console.log('Pinned Indicators:', pinnedIndicators);
      console.log('Module Name:', moduleName);
    }
  };

  /**
   * Handle Cancel
   */
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      console.log('onCancel not provided');
    }
  };

  return (
    <div
      style={{
        maxHeight: 400,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#FAF5F5',
        fontFamily: 'Arial, sans-serif',
        overflow: 'hidden', // prevent content from exceeding 400px
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto', // center horizontally
        padding: '1rem',
        fontSize: '16px',
        marginTop: '32px',
      }}
    >
      {/* Module Title */}
      <div
        style={{
          display: 'flex',
        }}
      >
        <span 
          style={{
            color: Colors.moaReds[2],
            paddingRight: '5px',
            fontSize: '24px',
          }}
        >*</span>
      <input
        type="text"
        value={moduleName}
        onChange={(e) => setModuleName(e.target.value)}
        placeholder="Name Module"
        style={{
          display: 'block',
          width: '100%',
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '0px',
          border: 'none',
          outline: 'none',
          backgroundColor: "#FFF", //'transparent',
          padding: '0',
        }}
      /></div>

      {/* Search Box */}
      <div style={{ position: 'relative', marginBottom: '1rem' }}>
        <div
          style={{
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        >
          <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
            <circle
              cx="18.3337"
              cy="11.6667"
              r="9.91667"
              fill="none"
              stroke="black"
              strokeWidth="3.5"
            />
            <path
              d="M2 28.0001L11.3333 18.6667"
              stroke="black"
              strokeWidth="3.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search List of Indicators"
          style={{
            width: '100%',
            fontSize: '1rem',
            padding: '10px 10px 10px 35px',
            border: 'none',
            outline: 'none',
            borderBottom: '2px solid #333',
            color: '#666',
            borderRadius: 0,
            backgroundColor: '#FAF5F5',
          }}
        />
      </div>

      {/* Middle container for pinned & search results, with flex to manage vertical space */}
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          backgroundColor: 'white',
        }}
      >
        {/* Pinned items */}
        {pinnedIndicators.length > 0 && (
          <div
            style={{
              minHeight: '75px',
              maxHeight: '150px',
              overflowY: 'auto',
              marginBottom: '0.5rem',
              borderBottom: '2px solid black',
            }}
          >
            <ul style={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
              {pinnedIndicators.map((indicator) => (
                <li
                  key={indicator.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #eee',
                    padding: '6px 0',
                    fontSize: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={true}
                    onChange={() => togglePinned(indicator)}
                    style={{
                      appearance: 'none',
                      WebkitAppearance: 'none',
                      border: '2px solid #000',
                      borderRadius: '50%',
                      width: '14px',
                      height: '14px',
                      outline: 'none',
                      cursor: 'pointer',
                      marginRight: '8px',
                      backgroundColor: '#000',
                    }}
                  />
                  <span>{indicator.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Search results */}
        <div style={{ flex: 1, overflowY: 'auto' }}>
          <ul
            style={{
              listStyleType: 'none',
              padding: 0,
              margin: 0,
              width: '100%',
              textAlign: 'left',
            }}
          >
            {filteredIndicators.length > 0 ? (
              filteredIndicators.map((item) => (
                <li
                  key={item.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #eee',
                    padding: '6px 0',
                    fontSize: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isPinned(item)}
                    onChange={() => togglePinned(item)}
                    style={{
                      appearance: 'none',
                      WebkitAppearance: 'none',
                      border: '2px solid #000',
                      borderRadius: '50%',
                      width: '14px',
                      height: '14px',
                      outline: 'none',
                      cursor: 'pointer',
                      marginRight: '8px',
                      backgroundColor: isPinned(item) ? '#000' : '#fff',
                    }}
                  />
                  <span>{item.name}</span>
                </li>
              ))
            ) : (
              <li style={{ padding: '10px', fontStyle: 'italic' }}>
                No results found
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* Add/Cancel Buttons */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <div
          style={{
            lineHeight: '30px',
            paddingRight: '10px',
            fontStyle: 'italic',
            color: '#bbbbbb',
          }}
        >{validated}</div>
        <button 
          onClick={handleAdd} 
          style={{ marginRight: '0.5rem', backgroundColor: 'white' }} 
          disabled={validated !== ""}
        >
          add module
        </button>
        <button onClick={handleCancel} style={{ backgroundColor: 'white' }}>
          cancel
        </button>
      </div>
    </div>
  );
};

export default ModuleSearch;
