import React from "react";

interface RSliderProps {
  rValue: number; // Expecting a value between -1 and 1
}

const interpolateColor = (color1: string, color2: string, factor: number): string => {
  const hexToRgb = (hex: string) =>
    hex
      .replace("#", "")
      .match(/.{1,2}/g)
      ?.map((x) => parseInt(x, 16)) || [0, 0, 0];

  const rgbToHex = (rgb: number[]) =>
    `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const resultRgb = rgb1.map((v, i) => Math.round(v + (rgb2[i] - v) * factor));
  return rgbToHex(resultRgb);
};

const getTooltipColor = (r: number): string => {
  if (r < 0) {
    // Transition from green to gray for negative values
    const factor = (r + 1) / 1; // Scale -1 to 0 to range 0 to 1
    return interpolateColor("#FFC425", "#D3D3D3", factor);
  } else {
    // Transition from gray to green for positive values
    const factor = r / 1; // Scale 0 to 1 to range 0 to 1
    return interpolateColor("#D3D3D3", "#FFC425", factor);
  }
};

const RSlider: React.FC<RSliderProps> = ({ rValue }) => {
  const clampedR = Math.max(-1, Math.min(1, rValue));
  const tooltipPosition = ((clampedR + 1) / 2) * 100; // Position tooltip on the slider
  const tooltipColor = getTooltipColor(clampedR); // Get color based on rValue

  return (
    <div className="r-slider-container">
      {/*<h2 className="r-title">Correlation Coefficient (r): {clampedR.toFixed(2)}</h2>*/}
      <div className="r-slider-wrapper">
        <div className="r-slider-scale">
          <div className="r-labels-above">
            <span>Strong</span>
            <span>Moderate</span>
            <span>Weak</span>
            <span>Moderate</span>
            <span>Strong</span>
          </div>
          <div
            className="r-tooltip"
            style={{
              left: `${tooltipPosition}%`,
              backgroundColor: tooltipColor,
            }}
          >
            <span>r = {clampedR.toFixed(2)}</span>
            <div
              className="r-tooltip-notch"
              style={{
                borderTopColor: tooltipColor,
              }}
            ></div>
            <div
              className="r-tooltip-notch-under"
              style={{
                borderTopColor: '#9E9C9C',
              }}
            ></div>
          </div>
          <div className="r-gradient"></div>
          <div className="r-scale-labels">
            <span className="r-scale-left">-1</span>
            <span className="r-scale-center">0</span>
            <span className="r-scale-right">1</span>
          </div>
        </div>
      </div>
      <p className="r-description">
        {/*Caution: Correlation is not causation. Confused?{" "}
        <a href="/correlation_causation.pdf" target="_blank" rel="noopener noreferrer">
          Read more here
        </a>.*/}
      </p>
    </div>
  );
};

export default RSlider;
