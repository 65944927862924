import React, { useState, useEffect } from 'react';
import { onSetModuleId, useGlobalModuleId } from '../../data/StatusStore';

// Modified searchData
interface searchData {
  [key: string]: {
    directive: string;
    data: string;
  };
}

const _searchData: searchData = {
  '90023': { directive: "zip", data: "Some data about 90023" },
  '3826 Lomitas Dr': { directive: "address", data: "Some data about 3826 Lomitas Dr" },
  'South Greenpoint': { directive: "area", data: "Some data about South Greenpoint" },
  'Income': { directive: "stat", data: "Some data about income" },
  'Asians': { directive: "population", data: "Some data about Asians" },
};



const SearchPopup = ({ 
  isOpen, 
  onClose,
  searchData,
  groupedModules,
  allData,
  setModuleIdIncrement,
  moduleIdIncrement,
 }: { 
  isOpen: boolean;
  onClose: () => void;
  searchData: any | null;
  groupedModules: any | null;
  allData: any | null;
  setModuleIdIncrement: Function;
  moduleIdIncrement: number;
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState<string[]>([]);
  const [outstandingSearch, setOutstandingSearch] = useState<boolean>(false);
  const moduleId = useGlobalModuleId();

  useEffect(() => {
    console.log("D101924 searchData", searchData);
  }, [searchData]);

  useEffect(() => {
    //console.log("Z102024 moduleId", moduleId);
    if (outstandingSearch){
      setModuleIdIncrement(moduleIdIncrement + 1);
      setOutstandingSearch(false);
    }
  }, [moduleId]);

  // Modified handleInputChange
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value) {
      // Get the keys from the searchData that match the search term
      const filtered = Object.keys(searchData).filter((key) =>
        key.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredResults(filtered); // Store matching keys
    } else {
      setFilteredResults([]); // Clear results when input is empty
    }
  };

    // Function to handle selection of a search result
    const handleSelect = (item: string) => {
      //console.log(`Z102024 You selected: ${item}`);
      //console.log("Z102024 searchData", searchData);
      //console.log("Z102024 searchData[item]", searchData[item]);
      setOutstandingSearch(true);
      onSetModuleId(searchData[item][0].directive_data);
      //setModuleIdIncrement(moduleIdIncrement + 1);
      // Logic to handle specific selection can go here
      // Example: navigate to a specific page or filter data based on selection
      onClose(); // Close the popup after selection
    };

    if (!isOpen) return null;

    return (
      <div
        style={{
          position: 'fixed',
          top: '50px',
          left: 250,
          width: '100vw',
          height: 'calc(100% - 50px)',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1001,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer',
            fontSize: '24px',
          }}
          onClick={onClose}
        >
          X
        </div>
        <div
          style={{
            textAlign: 'left',
            width: '80%',
            maxWidth: '600px',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search . . ."
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '2rem',
                border: 'none',
                borderBottom: '2px solid #333',
                outline: 'none',
                textAlign: 'left',
                color: '#666',
              }}
            />
            <div
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
            >
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
                <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
              </svg>
            </div>
          </div>
          {/* Typeahead Suggestions */}
          <ul
            style={{
              listStyleType: 'none',
              padding: 0,
              margin: 0,
              width: '100%',
              maxHeight: '200px',
              overflowY: 'auto',
              textAlign: 'left',
            }}
          >
            {filteredResults.length > 0 ? (
              filteredResults.map((item) => (
                <li
                  key={item}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    backgroundColor: '#f5f5f5',
                    marginBottom: '5px',
                  }}
                  onClick={() => handleSelect(item)}
                >
                  {item}
                </li>
              ))
            ) : (
              <li style={{ padding: '10px', fontStyle: 'italic' }}>No results found</li>
            )}
          </ul>
          <div style={{ marginTop: '30px', fontSize: '1.2rem' }}>
            <p>Type anything you want into this search bar - including areas, zip codes, population groups, statistics, or even combinations of statistics you’d like to see.</p>
            <p><b>Examples:</b></p>
            <ul            
              style={{
                listStyleType: 'none',
              }}
            >
              <li>Total Population</li>
              <li>Families</li>
              {/*<li>“90023”</li>
              <li>“3826 Lomitas Dr”</li>
              <li>“South Greenpoint”</li>
              <li>“Income”</li>
              <li>“Asians”</li>
              <li>“Life Expectancy by Race”</li>
              <li>“Median Income and Tree Density”</li>*/}
            </ul>
          </div>
        </div>
      </div>
    );
};

export default SearchPopup;
